import React from 'react';
import Button from '@mui/material/Button';
export default function InputHeading({ title, onClick }) {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <h2 className="dark-text">{title}</h2>
      <hr />
      <div>
        <Button
          size="small"
          variant="contained"
          onClick={onClick}
          disableElevation
          style={{ textTransformation: 'none' }}
        >
          Add
        </Button>
      </div>
    </div>
  );
}
