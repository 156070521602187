import React, { useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';

import SuccessAnim from './ui/Success';
import { SERVER_URL } from '../config';

const MiddleAlign = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  flex-direction: column;
  h2,
  p {
    z-index: 1;
    text-align: center;
  }
  p {
    margin-top: -10px;
  }
  a {
    text-align: center;
  }
`;
const Wrapper = styled.div`
  p {
    font-size: 14px;
  }

  .btn-layout {
    display: flex;
    justify-content: end;
  }
  .alert-danger {
    display: flex;
    align-items: center;
    border-color: #af121f;
    color: #a94442;
    background-color: #f2dede;
    padding: 15px;
    margin-bottom: 8px;
    border: 1px solid transparent;
    p {
      margin: 0;
      margin-left: 8px;
    }
  }
  .error-txt {
    color: #af121f;
  }
`;

const Title = styled.div`
  font-family: 'Lora', serif;
  font-weight: 500;
  font-size: 2em;
`;
const BtnWrapper = styled.div`
  .MuiButtonBase-root {
    border-radius: 5px;
    color: white;
    font-size: 0.875rem;
    /* background-color: #235680; */
    background-color: #c81631;

    :hover {
      box-shadow: 0 0 0px 2px #153e50;
      box-shadow: 0 0 0px 2px #c81631;
      border: 1px solid lightgray;
    }
  }
`;

function NewPassword({ email, code }) {
  const [data, setData] = useState({ password1: '', password2: '' });
  const [passwordNotMatched, setPasswordNotMatched] = useState(false);
  const [meetPasswordLength, setMeetPassLength] = useState(true);
  const [isSubmitting, setSubmitting] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleSubmitForm = () => {
    console.log(data);
    if (data.password2 == '' || data.password1 !== data.password2) {
      return alert('Please check your password!');
    }
    if (data.password1.length < 6) {
      setMeetPassLength(false);
    }
    setSubmitting(true);

    // Reset password obj
    const _resetPass = {
      email: email,
      code: code,
      newPassword: data.password1,
    };

    axios
      .post(`${SERVER_URL}/api/admin/update-password`, _resetPass)
      .then((res) => {
        setSubmitting(false);
        setSuccess(true);
      })
      .catch((err) => {
        alert(err.message);
        setSubmitting(false);
      });
  };

  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleClickShowPassword2 = () => {
    setShowPassword2(!showPassword2);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Wrapper>
      <div>
        {success ? (
          <MiddleAlign>
            <h2>Password Changed</h2>
            <SuccessAnim />
            <p>
              You password has been updated. Please login with your new
              password.
            </p>
            <br />
            <Link to="/login">Go to Login</Link>
          </MiddleAlign>
        ) : (
          <div>
            <Typography
              sx={{ marginTop: '1rem', fontWeight: 'bold', mb: '6px' }}
              component="h1"
              variant="h4"
            >
              New Password
            </Typography>

            <FormControl
              sx={{ marginTop: '1.5rem' }}
              fullWidth
              size="small"
              variant="outlined"
            >
              <InputLabel htmlFor="password">New password</InputLabel>
              <OutlinedInput
                value={data.password1}
                type={showPassword ? 'text' : 'password'}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                onChange={(e) => {
                  setData((state) => {
                    if (e.target.value.length >= 6) setMeetPassLength(true);
                    return {
                      ...state,
                      password1: e.target.value,
                    };
                  });
                }}
                label="New password"
              />
              {!meetPasswordLength && (
                <FormHelperText error>
                  Password length must be at least 6 charecters!
                </FormHelperText>
              )}
            </FormControl>

            <FormControl
              sx={{ marginTop: '1.5rem' }}
              fullWidth
              size="small"
              variant="outlined"
            >
              <InputLabel htmlFor="password">Confirm password</InputLabel>
              <OutlinedInput
                value={data.password2}
                type={showPassword2 ? 'text' : 'password'}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClickShowPassword2}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword2 ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                onChange={(e) => {
                  setData((state) => {
                    // password confirm
                    if (e.target.value.length >= state.password1.length)
                      setPasswordNotMatched(state.password1 !== e.target.value);
                    else setPasswordNotMatched(false);

                    // password length check
                    if (state.password1.length < 6) setMeetPassLength(false);

                    return {
                      ...state,
                      password2: e.target.value,
                    };
                  });
                }}
                label="Confirm password"
              />
              {meetPasswordLength && passwordNotMatched && (
                <FormHelperText error>Password do not matched!</FormHelperText>
              )}
            </FormControl>

            <BtnWrapper>
              <Button
                fullWidth
                disabled={isSubmitting}
                onClick={handleSubmitForm}
                sx={{
                  marginTop: '1.5rem',
                  marginBottom: '1rem',
                  minWidth: '140px',
                  minHeight: '42px',
                  fontWeight: '500',
                }}
                variant="contained"
                color="error"
                type="submit"
                disableElevation
              >
                {isSubmitting ? 'Please wait..' : 'UPDATE'}
              </Button>
            </BtnWrapper>
          </div>
        )}
      </div>
    </Wrapper>
  );
}

export default NewPassword;
