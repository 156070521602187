import styled from 'styled-components';
import React from 'react';
import { HoriSpace6px, Row, RowDivAlignCenter } from './share';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

const Wrapper = styled.div`
  padding: 8px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .MuiIconButton-root {
    border-radius: 50%;
    padding: 10px;
    border: 1px solid lightgray;
    svg {
      font-size: 20px;
    }
  }

  .action-div {
    display: flex;
  }

  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  margin-bottom: 8px;

  border: 1px solid transparent;
  :hover {
    border: 1px solid lightgray;
  }
`;

const Container = styled.div`
  h3 {
    margin: 0;
    padding: 0;
    line-height: 1.5;
    cursor: pointer;

    :hover {
      text-decoration: underline;
    }
  }
  p {
    margin: 0;
    margin-top: 3px;
    padding: 0;
    line-height: 1.4;
  }
  .desktop-normal-desc {
  }

  .mobile-normal-desc {
    display: none;
  }

  @media screen and (max-width: 892px) {
    .desktop-normal-desc {
      display: none;
    }

    .mobile-normal-desc {
      display: block;
    }
  }
`;

export default function NormalizeView({
  title,
  text,
  onDelete,
  onEdit,
  url,
  shorten,
}) {
  let str = text;
  if (str?.length > 240) {
    if (typeof str === 'string' || str instanceof String) {
      str = str.substring(0, 220) + '...';
    }
  }
  let mobile = text;
  if (mobile?.length > 100) {
    if (typeof mobile === 'string' || mobile instanceof String) {
      mobile = mobile.substring(0, 90) + '...';
    }
  }
  let short = text;
  if (short?.length > 35) {
    if (typeof short === 'string' || short instanceof String) {
      short = short.substring(0, 20) + '...';
    }
  }
  return (
    <Wrapper className="wrapper">
      <RowDivAlignCenter style={{ padding: '5px 10px' }}>
        <Container>
          <Tooltip title="Go to this page" placement="bottom-start">
            <h3 className="dark-text" onClick={url}>
              {title}
            </h3>
          </Tooltip>
          {shorten ? (
            <div>
              <p className="dark-text desktop-normal-desc">{str}</p>
              <p className="dark-text mobile-normal-desc">{short}</p>
            </div>
          ) : (
            <div>
              <p className="dark-text desktop-normal-desc">{str}</p>
              <p className="dark-text mobile-normal-desc">{mobile}</p>
            </div>
          )}
        </Container>
      </RowDivAlignCenter>

      <div className="action-div">
        <div>
          {onEdit && (
            <IconButton variant="outlined" size="small" onClick={onEdit}>
              <EditIcon />
            </IconButton>
          )}
        </div>
        <HoriSpace6px />
        <div>
          <IconButton
            id="delete-button"
            variant="outlined"
            size="small"
            color="error"
            onClick={onDelete}
          >
            <DeleteOutlineIcon />
          </IconButton>
        </div>
      </div>
    </Wrapper>
  );
}
