import styled from 'styled-components';

export const NewsletterWrapper = styled.div`
  padding: 8px;
  width: 100%;
  background-color: #fafafa;
  padding: 15px 16px;
  margin-bottom: 8px;


  .MuiIconButton-root {
    border-radius: 50%;
    padding: 10px;
    border: 1px solid lightgray;
    svg {
      font-size: 20px;
    }
  }

  .action-div {
    display: flex;
  }

  background-color: #fff;
  /* border-radius: 4px; */
  /* box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%); */
  margin-bottom: 8px;

  border: 1px solid transparent;
  :hover {
    /* border: 1px solid lightgray; */
    border: 1px solid lightblue;
  }
`;

export const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column wrap;
  max-width: 800px;
  margin-top: 3rem;
`;

export const Card = styled.div`
  padding: 1.5rem;
  color: inherit;
  text-decoration: none;
  border: 1px solid black;
  border-radius: 10px;
  transition: color 0.15s ease, border-color 0.15s ease;
  width: 100%;

  &:hover,
  :focus,
  :active {
    color: #0070f3;
    border-color: #0070f3;
  }
`;

export const StyledA = styled.a`
  margin: 0 0 1rem 0;
  font-size: 1.5rem;
`;
export const WideCardWrapper = styled.div`
  padding: 20px;
  display: flex;
  width: 100%;
  cursor: pointer;
  overflow: hidden;
  transition: all 0.2s;
  align-items: start;
  img {
    width: 30% !important;
    object-fit: contain;
  }
  :hover {
    border-radius: 3px;
    // box-shadow: 0 0 3px rgb(35 173 255);
    // -webkit-box-shadow: 0 0 3px rgba(35 173 255);
    box-shadow: 0 0 3px rgb(44 217 197 / 80%);
    -webkit-box-shadow: 0 0 3px rgb(44 217 197 / 80%);
    transform: scale(1.01);
  }
`;

export const WideCardDetailWrapper = styled.div`
  width: 100%;
  padding: 0 14px;
  .card-video-title {
    padding: 0;
    margin-bottom: 4px;
    text-overflow: wrap;
  }

  .card-video-text {
    padding-top: 4px;
    margin: 0;
    font-size: 14px;
    color: grey;
    text-overflow: ellipsis;
  }
`;
export const WideCardDetailWrapper2 = styled.div`
  width: 100%;
  padding: 0 20px;

  p,
  h2 {
    animation-duration: 1s;
    animation-fill-mode: both;
    animation-name: maskUp;
  }
  /* @keyframes muskUp {
    0% {
      transform: translate(0, 100%);
      visibility: 0;
    }
    100% {
      transform: translate(0, 0);
      visibility: 1;
    }
  } */

  @-webkit-keyframes maskUp {
    from {
      transform: translate(0, 100%);
      visibility: 0;
    }

    to {
      transform: translate(0, 0);
      visibility: 1;
    }
  }

  @keyframes maskUp {
    from {
      transform: translate(0, 100%);
    }

    to {
      transform: translate(0, 0);
    }
  }

  .card-video-title {
    padding: 0;
    margin-bottom: 4px;
    text-overflow: wrap;
  }

  .card-video-text {
    padding-top: 4px;
    margin: 0;
    font-size: 14px;
    color: grey;
    text-overflow: ellipsis;
  }
`;

export const PhoneCard = styled.div`
  cursor: pointer;
  transition: all 0.2s;
  width: 100%;

  .mobile-card-thumbnail {
    object-fit: contain;
    transform: scale(0.9);
  }

  .container-main-div {
    display: flex;
    width: 100%;
    height: 295px;
    // marginRight: 16px;
    flex-direction: column;
    border: 0.4px solid rgb(215 214 214);
    overflow: hidden;
    justify-content: space-between;
  }

  :hover {
    border-radius: 2px;
    // box-shadow: 0 0 3px rgb(35 173 255);
    // -webkit-box-shadow: 0 0 3px rgba(35 173 255);
    box-shadow: 0 0 3px rgb(44 217 197 / 80%);
    -webkit-box-shadow: 0 0 3px rgb(44 217 197 / 80%);
    transform: scale(1.02);

    .container-main-div {
      border-radius: 2px;
      border: 1px solid rgba(0, 0, 0, 0.23);
    }
  }

  .content-center {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8px 0;
  }

  .image-wrapper {
    width: 100%;
    padding: 10px 10px 0;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    @media (min-width: 380px) and (max-width: 475px) {
      width: 100% !important;
      height: calc(160px + ((100vw - 350px) * (45 / 225))) !important;
    }
    @media (min-width: 475px) {
      width: 210px !important;
      height: 180px !important;
    }
    @media (min-width: 640px) and (max-width: 1024px) {
      width: 100% !important;
      height: 180px !important;
    }
    @media (min-width: 1024px) {
      width: 100% !important;
      height: 180px !important;
    }
    @media (min-width: 1280px) {
      width: 100% !important;
      height: 180px;
      min-height: 180px;
    }
  }
`;

export const FeatureCardContainer = styled.div`
  display: flex;

  .feature-card-div1 {
    margin-right: 6px;
    width: 100%;
  }
  .feature-card-div2 {
    margin-left: 6px;
    width: 100%;
  }

  @media (max-width: 640px) {
    flex-direction: column;

    .feature-card-div1 {
      margin-right: 0px;
    }
    .feature-card-div2 {
      margin-left: 0;
      margin-top: -8px;
    }
  }
`;

export const SimilarPhoneCard = styled.div`
  cursor: pointer;
  display: flex;
  width: 100%;
  margin-right: 8px;
  flex-direction: column;
  border: 0.4px solid #f1efef;
  overflow: hidden;
  transition: all 0.2s;

  :hover {
    border-radius: 3px;
    // box-shadow: 0 0 3px rgb(35 173 255);
    // -webkit-box-shadow: 0 0 3px rgba(35 173 255);
    box-shadow: 0 0 3px rgb(44 217 197 / 80%);
    -webkit-box-shadow: 0 0 3px rgb(44 217 197 / 80%);
    transform: scale(1.02);
  }
  a {
    display: flex;
    flex-direction: column;
  }
`;
export const PricePhoneCardThumbnail = styled.div`
  transform: scale(0.9);
  max-width: 100px;
  max-height: 100px;
  justify-self: center;
  margin: 0 auto;
  img {
    height: 100%;
  }
`;

export const SidePhoneCardContainer = styled.div`
  cursor: pointer;
  display: flex;
  width: 100%;
  margin-right: 8px;
  flex-direction: column;
  border: 0.4px solid #f1efef;
  overflow: hidden;
  transition: all 0.2s;
  .mobile-card-thumbnail {
    object-fit: contain;
    transform: scale(0.9);
  }
  :hover {
    border-radius: 3px;
    // box-shadow: 0 0 3px rgb(35 173 255);
    // -webkit-box-shadow: 0 0 3px rgba(35 173 255);
    box-shadow: 0 0 3px rgb(44 217 197 / 80%);
    -webkit-box-shadow: 0 0 3px rgb(44 217 197 / 80%);
    transform: scale(1.02);
  }
`;

export const PhoneCardPrice = styled.div`
  padding: 0;
  margin: 0;
  text-align: center;
  font-size: 16px;
  color: #278575;
  font-weight: 500;
`;

export const PhoneCardTitle = styled.div`
  margin: 0;
  margin: 0;
  overflow: hidden;
  line-height: 1.5;
  font-weight: 700;
  text-align: center;
  font-size: 14px;
  /* padding: 0 0 5px; */
  color: #051145;
  display: block;
`;

export const SidePhoneCardThumbnail = styled.div`
  height: 100px;
  transform: scale(0.9);
`;

export const SidePhoneCardPrice = styled.p`
  padding: 0;
  margin: 0;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  color: #278575;
`;

export const SidePhoneCardTitle = styled.p`
  margin: 0;
  margin: 0;
  overflow: hidden;
  line-height: 1.3;
  font-weight: 400;
  text-align: center;
  font-size: 14px;
  /* padding: 0 0 5px; */
  color: #051145;
  display: block;
`;

export const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  padding-top: 56.25%;
  cursor: pointer;

  :hover {
    .card-video {
      opacity: 100;
    }

    .video-thumbnail {
      opacity: 0;
    }
  }

  img {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    pointer-events: none;
    object-fit: cover;
    min-width: 100%;
    min-height: 100%;
  }

  video {
    position: absolute;
    top: 0;
    opacity: 0;
    width: 100%;
    object-fit: cover;
    min-width: 100%;
    min-height: 100%;
  }
`;
export const KeyFeatureCard = styled.div`
  text-decoration: none;
  border-radius: 9px;
  border: solid 1px #d8d8d8;
  background-color: #fbfbfb;
  color: #333;
  width: 100%;
  padding: 8px 10px;
  margin: 8px 0;
  cursor: pointer;

  :hover {
    border-color: #05cfbc;
    background-color: #e9f5f4;
    // border-color: #1991ff;
    // background-color: #f2faff;
    outline: none;
  }
`;
