import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link, useSearchParams } from 'react-router-dom';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import { getBatchApi, getBatchUrl } from '../../utils';
import AlumniTable from '../ui/AlumniTable';
import SubmitLoading from '../SubmitLoading';
import { SERVER_URL } from '../../config';
import AddHeader from '../HeaderAlumni';

const BATCH_STARTER = 2015;

export default function () {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const [searchParams] = useSearchParams();
  let _batchNo = searchParams.get('batch');
  if (!_batchNo) {
    _batchNo = 1;
  }

  const [pageNo, setPageNo] = useState(1); // TODO: first time page no not being active
  // but id page no not found then being active

  const [pageCount, setPageCount] = useState(0);

  const handleChange = (event, value) => {
    setPageNo(value);
  };
  const fetch = async (url) => {
    try {
      console.log(url)
      const res = await axios.get(`${SERVER_URL}/api/${url}`);
      const batch = await axios.get(`${SERVER_URL}/api/batch`);
      setData(res.data.alumni);
      setPageCount(batch.data.length);
    } catch ({ response }) {
      alert(
        response?.data?.message ??
          'Error occurred while fetching data. Please try again later or contact the system administrator'
      );
      setData([]);
    } finally {
      setLoading(false);
    }
  };

  const refresh = () => {
    fetch(getBatchApi(_batchNo));
  };

  useEffect(() => {
    refresh();
    return () => {
      setData([]);
    };
  }, [_batchNo]);

  return (
    <>
      <AddHeader title="Alumni" />
      <br/>
      <SubmitLoading open={loading} />
      <div>
        <div>
          {_batchNo && (
            <div>
              <AlumniTable
                data={data}
                tableTitle={`Batch ${BATCH_STARTER + parseInt(_batchNo)}`}
                refresh={refresh}
              />
            </div>
          )}
        </div>
        <div style={{ margin: '26px 0' }}>
          <Pagination
            renderItem={(item) => {
              return (
                <PaginationItem
                  component={Link}
                  to={`${getBatchUrl(item.page)}`}
                  {...item}
                />
              );
            }}
            count={pageCount}
            variant="outlined"
            shape="rounded"
            page={pageNo}
            onChange={handleChange}
          />
        </div>
      </div>
    </>
  );
}
