import React from 'react';

export default function InputHeading({ title }) {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <h2 className="dark-text">
        {title}
      </h2>
    </div>
  );
}
