import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router';
import SubmitLoading from '../SubmitLoading';
import { SERVER_URL } from '../../config';
import AddHeader from '../HeaderAdmin';
import NormalizeDashboard from '../ui/NormalizeAdmin';
import styled from 'styled-components';
import useAuth from '../../contexts/AuthContext';

const Wrapper = styled.div`
  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
    max-width: 920px;
  }
  .full-width {
    width: 100%;
  }
  td,
  th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 12px;
  }
  .center-align {
    text-align: center;
  }

  tr:hover {
    background-color: rgba(0, 0, 0, 0.03);
  }

  .head {
    font-weight: 700;
    font-size: 16px;
    color: black;
  }
  .username-color {
    color: #d32f2f;
  }
  .role-color {
    color: #278575;
  }
  .phone-table {
    display: none;
  }
  .desktop-table {
    background-color: white;
  }
  @media screen and (max-width: 892px) {
    .phone-table {
      background-color: white;
      /* border: .5px solid lightgray; */
      margin: 6px 0;
      padding: 14px 4px;
      display: flex;
      flex-direction: column;
    }
    .desktop-table {
      display: none;
    }
  }
`;
export default function () {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [data, setData] = useState([]);
  const [isSuperAdmin, setAdminRole] = useState(false);
  const [loading, setLoading] = useState(true);

  const fetch = async () => {
    try {
      const res = await axios.get(`${SERVER_URL}/api/admin`);
      setData(res.data);
    } catch (err) {
      console.log(err);
      alert(
        err.response?.data?.message ??
          'Error occurred while fetching data. Please try again later or contact the system administrator'
      );
      setData([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetch();
    setAdminRole(currentUser.role === 'super');
  }, [currentUser]);

  const handleDelete = (id) => {
    axios
      .delete(`${SERVER_URL}/api/admin/${id}`, {
        headers: {
          'x-access-token': currentUser.accessToken,
        },
      })
      .then((res) => {
        fetch();
      })
      .catch((err) => {
        alert(
          err.response?.data?.message ??
            'Error occurred while fetching data. Please try again later or contact the system administrator'
        );
      });
  };

  return (
    <>
      <AddHeader title="Admin users" isSuper={isSuperAdmin} />
      <br />
      <SubmitLoading open={loading} />
      <div>
        <div>
          {data && (
            <Wrapper>
              <table>
                {data?.map((val, index) => (
                  <NormalizeDashboard
                    key={`item-${val.username}`}
                    index={index}
                    name={val.name}
                    email={val.email}
                    role={val.role}
                    username={val.username}
                    isSuperAdmin={isSuperAdmin}
                    superAccount={val.username === currentUser.username}
                    onEdit={() => {
                      navigate(`/admin/edit/${val.id}`);
                    }}
                    onDelete={() => {
                      handleDelete(val.username);
                    }}
                  />
                ))}
              </table>
            </Wrapper>
          )}
        </div>
      </div>
    </>
  );
}
