import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import logo from '../../assets/logo.png';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import useAuth from '../../contexts/AuthContext';
import { useNavigate } from 'react-router';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import SingleLvlMobileNav from './SingleLvlMobileNav';
import AdminPanelSettingsRoundedIcon from '@mui/icons-material/AdminPanelSettingsRounded';
import { STATIC_AVETER } from '../../constants';
import { CDN_URL } from '../../config';
import { navs } from '../sidebar/Sidebar';
import {
  ProfileCircleNav,
  Avatar,
  NavSection,
  NavBar,
} from './NavbarComponents';
import './Navbar.css';
import styled from 'styled-components';

const DesktopOnly = styled.div`
  display: block;
  @media (max-width: 400px) {
    display: none;
  }
`;

const BrandLogoImg = styled.div`
  width: auto;
  height: 42px;
  margin-right: 6px;

  img {
    height: 100%;
  }
  @media (max-width: 1024px) {
    height: 38px;
  }
`;

export default function ProfileView() {
  const { logout, currentUser } = useAuth();
  const [state, setState] = useState(false);
  const navigate = useNavigate();

  const handleSideBarClick = (index, redirect) => {
    navigate(redirect);
    setState(false);
  };
  const toggleDrawer = (toggle) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setState(toggle);
  };
  return (
    <>
      <NavBar className="navbar-laptop">
        <NavSection style={{ '--layout': 'start' }}>
          <Link to="/" style={{ textDecoration: 'none' }}>
            <div
              style={{ display: 'flex', color: 'grey', alignItems: 'center' }}
            >
              <BrandLogoImg>
                <img src={logo} alt={'MIST-IPE Alumni'} />
              </BrandLogoImg>
              <DesktopOnly>
                <h2
                  style={{
                    marginLeft: '4px',
                    fontWeight: '600',
                    fontSize: '1.2rem',
                    color: '#f2f2f2',
                  }}
                >
                  MIST-IPE Alumni
                </h2>
              </DesktopOnly>
            </div>
          </Link>
        </NavSection>
        <NavSection style={{ '--layout': 'end' }}>
          <div
            style={{ display: 'flex', alignItems: 'center', color: 'white' }}
          >
            {currentUser?.id ? (
              <Link
                to={`/profile`}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  color: '#d32f2f',
                }}
              >
                <ProfileCircleNav className="rounded-img">
                  <AdminPanelSettingsRoundedIcon />
                </ProfileCircleNav>
              </Link>
            ) : (
              <Link to="/login">
                <Avatar>
                  <AccountCircleRoundedIcon />
                </Avatar>
              </Link>
            )}
            {currentUser?.id && (
              <div>
                <div className="desktop-logout">
                  <Button onClick={logout} color="error" variant="contained">
                    Log out
                  </Button>
                </div>
                <div className="mobile-logout">
                  <Button onClick={logout}>Log out</Button>
                </div>
              </div>
            )}
            <div className="mobile-hamburger">
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={toggleDrawer(true)}
              >
                <MenuIcon />
              </IconButton>
            </div>
          </div>
        </NavSection>
      </NavBar>
      {state && (
        <SingleLvlMobileNav
          d={navs}
          callback={handleSideBarClick}
          o={state}
          close={toggleDrawer(false)}
        />
      )}
    </>
  );
}
