import React, { useState, useEffect } from "react";
import CloseIcon from '@mui/icons-material/Close';
import "./SingleLvlMobileNav.css";

export default function ({ d, callback, o, close }) {
  const [data, setData] = useState(d);
  const [open, setOpen] = useState(false);
  useEffect(() => {
    setData(d);
    setOpen(o);
  }, [d, o]);
  return open ? (
    <div className="m-nav-container">
      <div className="m-close-nav-btn" onClick={close}>
        <CloseIcon/>
      </div>
      {data.map((nav, index) => {
        return (
          <div
            className="m-nav-items"
            tabIndex={0}
            onFocus={() => {}}
            onClick={() => {
              setData(
                data.map((val, i) => {
                  if (!nav.values || nav.values.length < 1)
                    callback(index, nav.redirect);
                  if (i === index) {
                    return {
                      ...val,
                      expanded: !val.expanded,
                    };
                  }
                  return val;
                })
              );
            }}
          >
            <div className="nav-item">{nav.name}</div>

            {nav.values && nav.values.length > 1 && nav.expanded ? (
              <div className="m-dropdown-container">
                {nav.values.map((v, ind) => {
                  return (
                    <div
                      className={"dropdown-option"}
                      onClick={() => {
                        callback(index, v.redirect);
                        setData(
                          data.map((zz) => {
                            return {
                              ...zz,
                              expanded: false,
                            };
                          })
                        );
                      }}
                    >
                      {v.name}
                    </div>
                  );
                })}
              </div>
            ) : null}
          </div>
        );
      })}
    </div>
  ) : null;
}
