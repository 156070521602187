import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import Dropzone from 'react-dropzone';
import CloseIcon from '@mui/icons-material/Close';

import { RemoveItemBtn, UploadedItem } from '../styles/upload-styles';
import InputHeading from '../InputHeading';
import InputLabel from '../InputLabel';
import useAuth from '../../contexts/AuthContext';
import { TextArea, Example } from './components';
import { CDN_URL, SERVER_URL } from '../../config';
import { ReadContainer600 } from '../share';
import { isValidBatchName, roundDecimal } from '../../utils';
import AddActions from './helper/AddActions';
import './UploadProduct.css';

const textAreaStyle = {
  padding: '4px 6px',
  width: '100%',
  textAlign: 'start',
  boxSizing: 'border-box',
  fontSize: '1rem',
  fontFamily:
    '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
};

export default function ({ edit }) {
  const { currentUser } = useAuth();
  const { id } = useParams();
  const navigate = useNavigate();

  const [data, setData] = useState({
    title: '',
    desc: '',
    images: [],
    actions: [],
  });
  const [uploadStatus, setUploadState] = useState({
    isUploading: false,
    uploadPercentage: 0,
    fileUploading: false,
    productUploading: false,
    progressbar: false,
  });
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [batchOk, setBatchTest] = useState(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (edit) {
      axios
        .get(`${SERVER_URL}/api/batch/${id}`)
        .then((res) => {
          setData(res.data);
          setUploadedFiles(res.data.images);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [edit]);

  const changeTitle = (event) => {
    setData({ ...data, title: event.currentTarget.value });
  };

  const submitPost = async (e) => {
    e.preventDefault();

    if (uploadedFiles.length == 0) {
      return alert('Please insert at least one image for this batch!');
    }

    setLoading(true);
    setUploadState((prevState) => ({
      ...prevState,
      productUploading: true,
    }));

    const requestData = {
      ...data,
      images: uploadedFiles,
    };
    const requestConfig = {
      headers: {
        'x-access-token': currentUser.accessToken,
      },
    };

    try {
      if (edit) {
        await axios.put(
          `${SERVER_URL}/api/batch/${data._id}`,
          requestData,
          requestConfig
        );
      } else {
        await axios.post(`${SERVER_URL}/api/batch`, requestData, requestConfig);
      }

      navigate('/batch');
    } catch (error) {
      alert(
        error.response?.err === undefined
          ? 'Internal Server Error'
          : error.response.err
      );
    } finally {
      setLoading(false);
      setUploadState((prevState) => ({
        ...prevState,
        productUploading: false,
      }));
    }
  };

  const onDrop = (files) => {
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      addInput(file);
    }
  };

  // TODO: allow only image!
  const addInput = (file) => {
    if (!file) return;
    let formData = new FormData();
    formData.append('file', file);

    setUploadState({ ...uploadStatus, fileUploading: true });
    const config = {
      header: { 'content-type': 'multipart/form-data' },
      onUploadProgress: (progressEvent) => {
        setUploadState({
          ...uploadStatus,
          uploadPercentage: roundDecimal((progressEvent / 1) * 100, 2),
          progressbar: true,
        });
      },
    };
    axios
      .post(`${CDN_URL}/api/upload`, formData, config)
      .then((res) => {
        if (res.data.success) {
          console.log(res.data);
          setUploadedFiles((files) => [...files, res.data.filename]);
        } else {
          alert('failed to save the files');
        }
        setUploadState({
          ...uploadStatus,
          uploadPercentage: 0,
          uploadCompleted: true,
          isUploading: false,
        });
        setUploadState({ ...uploadStatus, fileUploading: false });
      })
      .catch((err) => {
        setUploadState({ ...uploadStatus, fileUploading: false });
      });
  };

  const actionsInput = (secIndex, o) => {
    console.log(o);
    const fields = data.actions;
    if (o == null) {
      fields.pop(secIndex); // remove
    } else {
      if (fields.length <= secIndex) fields.push(o); // add new
      else fields[secIndex] = o; // update old
    }
    setData({ ...data, actions: fields });
  };

  return (
    <ReadContainer600>
      <InputHeading
        title={edit ? 'Edit Batch' : 'Add Batch'}
        btnTxt="Publish"
        submit={submitPost}
        loading={loading}
        back="/batch"
      />
      <form onSubmit={submitPost} className="upload-moadal">
        <InputLabel label="Title" />
        <TextArea
          id="title"
          label="title"
          rows="1"
          onChange={changeTitle}
          value={data.title}
        />
        <InputLabel label="Short description" />
        <textarea
          id="short"
          label="short"
          placeholder="Within 90-120 char"
          style={textAreaStyle}
          rows="2"
          onChange={(e) => {
            setData({ ...data, short: e.currentTarget.value });
          }}
          value={data.short}
        />
        <InputLabel label="Batch *" />
        <TextArea
          id="batch"
          label="batch"
          placeholder="Must start with 'IPE-'"
          rows="1"
          onChange={(e) => {
            const val = e.target.value;
            const test = isValidBatchName(val);
            setBatchTest(test);
            setData({ ...data, batch: val });
          }}
          value={data.batch}
        />
        <Example color={batchOk ? 'grey' : 'red'}>example: IPE-01</Example>
        <InputLabel label="Year *" />
        <TextArea
          id="year"
          label="year"
          placeholder="Year"
          rows="1"
          onChange={(e) => setData({ ...data, year: e.target.value })}
          value={data.year}
        />
        <Example>example: 2016</Example>
        <InputLabel label="Full description" />
        <textarea
          id="desc"
          label="desc"
          placeholder="Within 150-300 char"
          style={textAreaStyle}
          rows="6"
          onChange={(e) => {
            setData({ ...data, desc: e.currentTarget.value });
          }}
          value={data.desc}
        />
        <br />
        <br />
        <InputLabel label="Cover Images" />
        <div className="grid-layout">
          {uploadedFiles.map((val, index) => (
            <UploadedItem key={val}>
              <img
                key={`${index}-${val}`}
                style={{ width: '100%' }}
                alt="thumbnail"
                src={`${CDN_URL}/${val}`}
              />
              <RemoveItemBtn
                onClick={() => {
                  axios
                    .delete(`${CDN_URL}/api/${val}`)
                    .then((res) => {
                      if (res.data.success) {
                        setUploadedFiles(
                          uploadedFiles.filter((_, i) => i !== index)
                        );
                      } else alert(res.data);
                    })
                    .catch((e) => {
                      alert(e);
                    });
                }}
              >
                <CloseIcon />
              </RemoveItemBtn>
            </UploadedItem>
          ))}
          <div id="drop-zone-wrapper">
            <Dropzone onDrop={onDrop} multiple={true} maxSize={800000000}>
              {({ getRootProps, getInputProps }) => (
                <div
                  className="hover-lightgrey"
                  style={{
                    width: '130px',
                    height: '120px',
                    display: 'flex',
                    border: '.5px dashed lightgray',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  {...getRootProps()}
                >
                  <input {...getInputProps()} id="input-file" />

                  <div
                    style={{
                      height: '100%',
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    {uploadStatus.isUploading && (
                      <CircularProgress
                        className="dz-progress"
                        disableShrink
                        size="24px"
                        style={{ color: 'lightgrey' }}
                      />
                    )}
                    {uploadStatus.isUploading && (
                      <p style={{ color: 'grey', fontSize: '12px' }}>
                        `${uploadStatus.uploadPercentage}%`
                      </p>
                    )}

                    {!uploadStatus.isUploading && (
                      <div
                        style={{
                          fontSize: '12px',
                          display: 'flex',
                          flexDirection: 'row',
                          fontWeight: 'bold',
                          color: 'rgb(99, 91, 255)',
                          alignItems: 'center',
                          cursor: 'pointer',
                        }}
                      >
                        <ArrowUpwardIcon
                          style={{ fontSize: '16px', marginRight: '2px' }}
                        />
                        <p style={{ margin: '0' }}>Upload</p>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </Dropzone>
          </div>
        </div>
        <br />
        <AddActions onChange={actionsInput} val={data} />
        <br />
        <br />
      </form>
    </ReadContainer600>
  );
}
