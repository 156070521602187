import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

import AddHeader from '../AddHeader2';
import SubmitLoading from '../SubmitLoading';
import { SERVER_URL } from '../../config';
import { ReadContainer600 } from '../share';
import { date } from '../../utils';
import withDeleteHandler from '../ui/DeleteWrapper';
import useAuth from '../../contexts/AuthContext';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '1px solid #e2e2e2',
  boxShadow: 24,
  p: 4,
};

const Card = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;

  padding: 0 10px;
  background-color: #fff1f1;
  margin-bottom: 8px;

  border-radius: 4px;
  box-shadow: 0px 1px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  cursor: pointer;
`;
const NoticeWrapper = styled.div`
  padding: 4px 0;

  padding: 5px 0;
  h2 {
    font-size: 30px;
    color: #051145;
    margin: 0;
    padding: 4px 0;

    font-size: 16px;
    font-weight: 600;
    color: #212529;
    line-height: 20px;
  }
  p {
    margin: 0;
    padding: 0;

    color: #bf1430;
    font-size: 13px;
    font-weight: 600;
    line-height: 20px;
  }
  .msg {
    color: #81868c;
    font-weight: 500;
  }
`;

const DeleteWrapper = styled.div`
  display: flex;
`;
const GapH = styled.div`
  width: 5px;
`;

const AllContact = ({ alertDelete }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const { currentUser } = useAuth();
  const [instance, setInstance] = useState([]);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    fetch();
  }, []);

  const fetch = () => {
    axios
      .get(`${SERVER_URL}/api/contact`)
      .then((res) => setData(res.data))
      .catch(({ response }) =>
        alert(
          response?.data?.message ??
            'Error occurred while fetching data. Please try again later or contact the system administrator'
        )
      )
      .finally(() => setLoading(false));
  };

  const handleDelete = (id) => {
    setLoading(true);
    axios
      .delete(`${SERVER_URL}/api/contact/${id}`, {
        headers: { 'x-access-token': currentUser.accessToken },
      })
      .then((res) => fetch())
      .catch(({ response }) => {
        alert(response?.data?.message ?? 'Unable to delete this notice');
      })
      .finally(() => setLoading(false));
  };

  const showInstance = (val) => {
    setInstance(val);
    handleOpen(true);
  };

  return (
    <ReadContainer600>
      <AddHeader title="Help Request" />
      <hr />
      <SubmitLoading open={loading} />
      <div>
        {data?.map((val) => (
          <Card onClick={() => showInstance(val)}>
            <NoticeWrapper>
              <h2>{val.subject}</h2>
              <p>{val.mistId}</p>
              <p className="msg">{date(val.updatedAt)}</p>
              {/* <p className="msg">{val.message}</p> */}
            </NoticeWrapper>
            <DeleteWrapper>
              {/* <Button
                variant="outlined"
                color="warning"
                disableElevation
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  showInstance(val);
                }}
              >
                Read
              </Button>
              <GapH /> */}

              <Button
                variant="contained"
                // color="warning"
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  alertDelete(
                    'Are you sure to mark this issue as resolved?',
                    (sure) => {
                      if (sure) handleDelete(val._id);
                    }
                  );
                }}
                disableElevation
              >
                Resolved
              </Button>
            </DeleteWrapper>
          </Card>
        ))}
      </div>

      <div>
        <Modal open={open} onClose={handleClose}>
          <Box sx={style}>
            <p style={{ color: '#656565' }}>{date(instance.updatedAt)}</p>
            <h2>{instance.subject}</h2>

            <p>{instance.message}</p>

            <br />
            <p>{instance.name}</p>
            <p>{instance.mistId}</p>
            <p>{instance.email}</p>
            <p>{instance.phone}</p>
          </Box>
        </Modal>
      </div>
    </ReadContainer600>
  );
};
export default withDeleteHandler(AllContact);
