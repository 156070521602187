import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import axios from 'axios';
import styled from 'styled-components';

import AddHeader from '../AddHeader';
import useAuth from '../../contexts/AuthContext';
import NormalizeOther from '../NormalizeOther';
import NormalizeStaticPages from '../NormalizeStaticPages';
import SubmitLoading from '../SubmitLoading';
import { SERVER_URL, CLIENT_URL } from '../../config';
import { ReadContainer600 } from '../share';
import withDeleteHandler from '../ui/DeleteWrapper';

const SectionWrapper = styled.div`
  width: 100%;
  /* padding: 16px; */
  padding-top: 6px;
  margin-bottom: 8px;
  border-radius: 4px;
  background-color: #fafafa;

  h2 {
    font-size: 1.3rem;
    margin: 0;
    padding: 0;
  }
`;

const MyComponent = ({ alertDelete }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [messagePage, setMessagePage] = useState([]);
  const {
    currentUser: { accessToken },
  } = useAuth();
  const [staticPage, setStaticPage] = useState([
    'home',
    'noticeboard',
    'newsletter',
    'contacts-links',
    'help',
    'registration',
    'FAQ',
  ]);
  const navigate = useNavigate();

  const fetch = () => {
    axios
      .get(`${SERVER_URL}/api/page`)
      .then((res) => {
        const _otherPages = [];
        const _messagePages = [];
        res.data?.forEach((page) => {
          if (page.type === 'page') {
            return _otherPages.push(page);
          } else if (page.type === 'faculty') {
            return _messagePages.push(page);
          }
        });
        setData(_otherPages);
        setMessagePage(_messagePages);
      })
      .catch(({ response }) => {
        alert(
          response?.data?.message ??
            'Error occurred while fetching data. Please try again later or contact the system administrator'
        );
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetch();
  }, []);

  const handleDelete = (id) => {
    setLoading(true);
    axios
      .delete(`${SERVER_URL}/api/page/${id}`, {
        headers: {
          'x-access-token': accessToken,
        },
      })
      .then((res) => fetch())
      .catch(({ response }) => {
        alert(response?.data?.message ?? 'Unable to delete this page.');
      })
      .finally(() => setLoading(false));
  };

  return (
    <ReadContainer600>
      {/* <AddHeader title="Page" /> */}
      <SubmitLoading open={loading} />
      <div>
        {/* 1# */}
        <SectionWrapper>
          <h2>Special Pages</h2>
          <hr />
          {messagePage.map((val, index) => (
            <NormalizeStaticPages
              key={`item-${index}`}
              index={index}
              title={val.title}
              onEdit={() => {
                navigate(`special/edit/${val.url}`);
              }}
              onGoto={() => {
                window.open(`${CLIENT_URL}/${val.url}`, '_blank');
              }}
            />
          ))}
          <br />
        </SectionWrapper>

        {/* #2 */}
        <SectionWrapper>
          <h2>Main Pages</h2>
          <hr />
          {staticPage.map((val, index) => (
            <NormalizeStaticPages
              key={`item-${index}`}
              index={index}
              title={val + ' page'}
              onEdit={() => {
                navigate(val);
              }}
              onGoto={() => {
                window.open(`${CLIENT_URL}/${val.toLowerCase()}`, '_blank');
              }}
            />
          ))}
          <br />
        </SectionWrapper>

        {/* #3 */}
        <SectionWrapper>
          <h2>Other Pages</h2>
          <hr />
          {/* <AddHeader title="Other Pages" />
          <hr /> */}
          {data.map((val, index) => (
            <NormalizeOther
              key={`item-${index}`}
              index={index}
              title={val.title}
              text={val.desc}
              onEdit={() => {
                navigate(`edit/${val.url}`);
              }}
              // onDelete={() => {
              //   alertDelete('Are you sure to delete?', (sure) => {
              //     if (sure) handleDelete(val.url);
              //   });
              // }}
              onGoto={() => {
                window.open(`${CLIENT_URL}/${val.url}`, '_blank');
              }}
            />
          ))}
          <br />
        </SectionWrapper>
      </div>
    </ReadContainer600>
  );
};
export default withDeleteHandler(MyComponent);
