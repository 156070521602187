import React, { useEffect, useState } from 'react';
import axios from 'axios';
// import { Formik } from 'formik';
import { useParams } from 'react-router';
import * as yup from 'yup';
import styled from 'styled-components';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { useNavigate } from 'react-router';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import { Formik, Form, Field } from 'formik';

import InputHeading from '../InputHeading2';
import useAuth from '../../contexts/AuthContext';
import { SERVER_URL, CDN_URL } from '../../config';
import { NO_PROFILE } from '../../constants';
import { ReadContainer600 } from '../share';
import './UploadProduct.css';

const Wrapper = styled.div`
  padding: 16px 26px;
  width: 100%;
  max-width: 520px;
  h2 {
    margin: 0;
    padding: 0;
    margin-bottom: 16px;
  }
`;
const ProfileImage = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 120px;
  width: 120px;
  margin-bottom: 20px;
  border: 2px solid lightgray;
  overflow: hidden;

  :hover {
    background-color: lightgrey;
    .update-photo {
      z-index: 2;
      display: block;
      justify-content: center;
      align-items: center;
    }
    p {
      z-index: 3;
      display: block;
    }
  }

  :active {
    border: 2px solid lightblue;
  }

  :focus {
    border: 2px solid blue;
  }

  img {
    height: 100%;
    width: 100%;
    cursor: pointer;
  }

  p {
    position: absolute;
    display: none;
  }

  .update-photo {
    cursor: pointer;
    margin-top: -40px;
    position: absolute;
    display: none;
    height: 160px;
    width: 100%;
    color: lightgrey;
    background-color: #00000042;
  }
  .previous-photo {
    object-fit: cover;
  }
`;

export default function ({ edit }) {
  const navigate = useNavigate();

  const { currentUser } = useAuth();
  const { id } = useParams();

  const [options, setBatches] = useState('');
  const [loading, setLoading] = useState(true);
  const [image, setImage] = useState();
  const [editData, setEditData] = useState({});
  const [alumni, setAlumni] = useState({
    name: '',
    batchId: '',
    mistId: '',
    passingYear: '',
    email: '',
    phone: '',
  });

  // ---
  const emailExists = async (email) => {
    if (email && editData.email && editData.email == email.trim()) {
      return false;
    }
    try {
      const response = await axios.post(`${SERVER_URL}/api/user/email`, {
        email,
      });

      return !!response.data.email;
    } catch (error) {
      return false;
    }
  };

  const idExists = async (id) => {
    if (id && editData.mistId && editData.mistId == id) {
      // only check value mistId is a string & id is a number
      return false;
    }
    try {
      const response = await axios.get(`${SERVER_URL}/api/user/${id}`);
      return !!response.data.id;
    } catch (error) {
      return false;
    }
  };

  const validationSchema = yup.object().shape({
    name: yup.string().required("Student's name is required"),
    mistId: yup
      .number()
      .required('MIST ID is required')
      .test(
        'len',
        'MIST ID must be at least 9 digits',
        (val) => val?.toString().length >= 9
      )
      .test(
        'ID-exists',
        'This student ID already exists',
        async function (value) {
          return !(await idExists(value));
        }
      ),
    batchId: yup.string().required('IPE batch is required'),
    passingYear: yup
      .number()
      .required('Year of graduation is required')
      .typeError('Year of graduation must be a year')
      .integer('Year of graduation must be a year'),
    phone: yup.number().typeError('Phone number must be a number'),
    email: yup
      .string()
      .required('Email address is required')
      // .email('Invalid email format. e.g example@gmail.com') // Validation not working properly
      .test(
        'email-exists',
        'This email already exists',
        async function (value) {
          return !(await emailExists(value));
        }
      ),
  });

  // ---
  const fetchAlumni = () => {
    axios
      .get(`${SERVER_URL}/api/user/${id}`)
      .then((res) => {
        if (edit) {
          setEditData(res.data);
        }
        setAlumni(res.data);
        setImage(res.data.image);
      })
      .catch(({ response }) =>
        alert(
          response?.data?.message ??
            'Error occurred while fetching data. Please try again later or contact the system administrator'
        )
      )
      .finally(() => setLoading(false));
  };

  const fetchBatch = () => {
    axios
      .get(`${SERVER_URL}/api/batch`)
      .then((res) => {
        setBatches(
          res.data.map((val) => {
            return { value: val._id, label: val.batch };
          })
        );
      })
      .catch(({ response }) =>
        alert(
          response?.data?.message ??
            'Error occurred while fetching data. Please try again later or contact the system administrator'
        )
      )
      .finally(() => {
        if (!edit) setLoading(false);
      });
  };

  useEffect(() => {
    fetchBatch();
    if (edit) fetchAlumni();
  }, []);

  const handleSubmitForm = async (values, { setSubmitting }) => {
    if (currentUser && !currentUser.accessToken) {
      return alert('Please log in first.');
    }
    setSubmitting(true);

    const userData = values;
    userData.image = image;
    if (edit) {
      // handle email subscription status
      if (alumni.email) {
        if (alumni.email !== values.email) {
          // add it to subscription and remove old email
          const oldEmail = alumni.email;
          const subs = [];
          if (alumni.subscription && alumni.subscription.length > 0) {
            alumni.subscription.map((emailSubs) => {
              if (oldEmail == emailSubs) return;
              // add previous subs email
              subs.push(emailSubs);
            });
            // add new email to subscription
            subs.push(values.email);
            userData.subscription = subs;
          } else {
            userData.subscription = [values.email];
          }
        } else {
          // if matched then nothing to do
        }
      } else {
        // no previous email
        if (values.email) {
          userData.subscription = [values.email];
        }
      }
    } else {
      // user registration time add email as subscription
      userData.subscription = [values.email];
    }

    if (edit) {
      axios
        .put(`${SERVER_URL}/api/user/admin/${id}`, userData, {
          headers: {
            'x-access-token': currentUser.accessToken,
          },
        })
        .then((res) => navigate('/alumni'))
        .catch((err) => {
          console.log(err.response);
          alert(
            err.response?.data?.message ??
              'Error occurred while updating user. Please try again later or contact the system administrator'
          );
        })
        .finally(() => setLoading(false));
    } else {
      axios
        .post(`${SERVER_URL}/api/user`, userData, {
          headers: {
            'x-access-token': currentUser.accessToken,
          },
        })
        .then((res) => navigate('/alumni'))
        .catch((err) => {
          console.log(err.response);
          alert(
            err.response?.data?.message ??
              'Error occurred while updating user. Please try again later or contact the system administrator'
          );
        })
        .finally(() => setLoading(false));
    }
  };

  const selectProfilePhoto = (event) => {
    if (!event.target.files[0]) return;
    let formData = new FormData();
    formData.append('file', event.target.files[0]);
    axios
      .post(`${CDN_URL}/api/image`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        if (res.data.success) {
          setImage(res.data.filename);

          // // Update image immediately
          // if (edit) {
          //   axios
          //     .put(
          //       `${SERVER_URL}/api/user/admin/${id}`,
          //       { image: res.data.filename },
          //       {
          //         headers: {
          //           'x-access-token': currentUser.accessToken,
          //         },
          //       }
          //     )
          //     .then((res) => {})
          //     .catch(({ response }) => {
          //       setImage(null);
          //       alert(
          //         response?.data?.message ??
          //           'Error occurred while fetching data. Please try again later or contact the system administrator'
          //       );
          //     });
          // }
        } else {
          alert("Something wrong! Can't upload this image");
        }
      })
      .catch(({ response }) => {
        alert(
          response?.data?.message ??
            'Error occurred while uploading photo. Please try again later or contact the system administrator'
        );
      });
  };

  return (
    <ReadContainer600>
      <InputHeading
        title={edit ? 'Edit Alumni' : 'Register Alumni'}
        back="/alumni"
      />

      {loading ? (
        <>Loading..</>
      ) : (
        <Formik
          initialValues={alumni}
          validationSchema={validationSchema}
          onSubmit={handleSubmitForm}
        >
          {({
            values,
            setFieldValue,
            setFieldTouched,
            errors,
            isSubmitting,
            touched,
          }) => (
            <Form>
              <br />
              <br />
              <ProfileImage>
                {image ? (
                  <img
                    className="previous-photo"
                    src={`${CDN_URL}/${image}`}
                  ></img>
                ) : (
                  <img
                    className="previous-photo"
                    src={`${CDN_URL}/${
                      alumni.image ? alumni.image : NO_PROFILE
                    }`}
                  ></img>
                )}
                <input
                  id="input-image"
                  className="update-photo"
                  type="file"
                  accept="image/*"
                  onChange={selectProfilePhoto}
                />
              </ProfileImage>
              <Field
                id="name"
                name="name"
                as={TextField}
                label="Name"
                variant="outlined"
                margin="normal"
                fullWidth
                error={touched.name && Boolean(errors.name)}
                helperText={touched.name && errors.name}
              />
              <FormControl
                fullWidth
                margin="normal"
                error={touched.batchId && Boolean(errors.batchId)}
              >
                <InputLabel id="batch-select-label">Batch *</InputLabel>
                <Field
                  as={Select}
                  labelId="batch-select-label"
                  id="batchId"
                  name="batchId"
                  label="Batch"
                  variant="outlined"
                  value={
                    (options &&
                      options.find((option) => option.value === values.batchId)
                        ?.value) ||
                    ''
                  }
                  onChange={(event) => {
                    const { value } = event.target;
                    setFieldValue('batchId', value);
                  }}
                  onBlur={() => setFieldTouched('batchId', true)}
                >
                  {options &&
                    options.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                </Field>
                {touched.batchId && errors.batchId && (
                  <div>{errors.batchId}</div>
                )}
              </FormControl>

              <Field
                id="mistId"
                name="mistId"
                as={TextField}
                label="MIST ID"
                variant="outlined"
                margin="normal"
                fullWidth
                type="number"
                error={touched.mistId && Boolean(errors.mistId)}
                helperText={touched.mistId && errors.mistId}
              />

              <Field
                id="passingYear"
                name="passingYear"
                as={TextField}
                label="Year of graduation"
                variant="outlined"
                margin="normal"
                fullWidth
                type="number"
                error={touched.passingYear && Boolean(errors.passingYear)}
                helperText={touched.passingYear && errors.passingYear}
              />

              <Field
                id="phone"
                name="phone"
                as={TextField}
                label="Phone number"
                variant="outlined"
                margin="normal"
                fullWidth
                type="number"
                error={touched.phone && Boolean(errors.phone)}
                helperText={touched.phone && errors.phone}
              />

              <Field
                id="email"
                name="email"
                as={TextField}
                label="Email address"
                variant="outlined"
                margin="normal"
                fullWidth
                error={touched.email && Boolean(errors.email)}
                helperText={touched.email && errors.email}
              />

              {/* <button type="submit">Submit</button> */}
              <Button
                disableElevation
                disabled={isSubmitting}
                sx={{
                  marginTop: '1rem',
                  marginBottom: '1rem',
                  minWidth: '140px',
                  minHeight: '42px',
                }}
                variant="contained"
                color="primary"
                type="submit"
                id="submit-button"
              >
                {isSubmitting ? 'Please wait..' : edit ? 'Update' : 'Register'}
              </Button>
            </Form>
          )}
        </Formik>
      )}
    </ReadContainer600>
  );
}
