import React from 'react';
import { ReadContainer } from '../components/share';
import AllFooter from '../components/read/AllFooter';

export default function IndexView() {
  return (
    <ReadContainer>
      <AllFooter/>
    </ReadContainer>
  );
}
