import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link, useSearchParams } from 'react-router-dom';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';

import useAuth from '../../contexts/AuthContext';
import AddHeader2 from '../AddHeader2';
import NormalizeView from '../NormalizeView3';
import NormalizeViewPending from '../NormalizeViewPending';
import SubmitLoading from '../SubmitLoading';
import { ReadContainer600, Row } from '../share';
import { SERVER_URL, NEWSLETTER_ROUTE, CLIENT_URL } from '../../config';
import { getNewsletterApi, getNewsletterUrl } from '../../utils';
import { CenterSpan, SectionTitle } from '../styles/shared-styles';
import { H4px } from '../styles/gap-styles';
import withDeleteHandler from '../ui/DeleteWrapper';

const MyComponent = ({ alertDelete }) => {
  const [loading, setLoading] = useState(true);
  const {
    currentUser: { accessToken },
  } = useAuth();
  const [pending, setPending] = useState([]);
  const [data, setData] = useState([]);

  const handleDelete = (id) => {
    setLoading(true);
    axios
      .delete(`${SERVER_URL}/api/newsletter/${id}`, {
        headers: {
          'x-access-token': accessToken,
        },
      })
      .then((res) => {
        fetch(getNewsletterApi(perPage, pageNo));
        setLoading(false);
      })
      .catch(({ response }) => {
        alert(response?.data?.message ?? 'Unable to delete this newsletter');
        setLoading(false);
      });
  };
  //--------------------------------------------------------------------
  // post pagination block start
  const [searchParams] = useSearchParams();
  const _pageNo = searchParams.get('pageNo');

  const [pageNo, setPageNo] = useState(_pageNo == null ? 0 : _pageNo);
  const perPage = 4;
  const [pageCount, setPageCount] = useState(1);

  const handleChange = (event, value) => {
    setPageNo(value);
  };

  const totalPages = (itemCount) => {
    let cout = Math.ceil(itemCount / perPage);
    setPageCount(cout);
  };

  const fetch = (url) => {
    axios
      .get(`${SERVER_URL}/api/${url}`)
      .then((res) => {
        setData(res.data.payload ? res.data.payload : []);
        totalPages(res.data.totalCount ? res.data.totalCount : 0);
      })
      .catch(({ response }) => {
        alert(
          response?.data?.message ??
            'Error occurred while fetching data. Please try again later or contact the system administrator'
        );
        setData([]);
        totalPages(0);
      })
      .finally(() => setLoading(false));

    axios
      .get(`${SERVER_URL}/api/newsletter?include=pending`)
      .then((res) => {
        setPending(res.data);
      })
      .catch(({ response }) => {
        alert(
          response?.data?.message ??
            'Error occurred while fetching data. Please try again later or contact the system administrator'
        );
      });
  };

  useEffect(() => {
    fetch(getNewsletterApi(perPage, pageNo));
  }, [_pageNo]);
  // post pagination block end
  //--------------------------------------------------------------------

  const handleApprove = (id) => {
    setLoading(true);
    console.log(accessToken);
    axios
      .put(
        `${SERVER_URL}/api/newsletter/approve/${id}`,
        {},
        {
          headers: {
            'x-access-token': accessToken,
          },
        }
      )
      .then((res) => {
        fetch(getNewsletterApi(perPage, pageNo));
      })
      .catch(({ response }) => {
        alert(response?.data?.message ?? 'Unable to delete this newsletter');
      })
      .finally(() => setLoading(false));
  };
  return (
    <ReadContainer600>
      <AddHeader2 title="Newsletters" />
      <br />
      <SubmitLoading open={loading} />
      <div>
        <SectionTitle>Pending</SectionTitle>
        <hr />
        {pending?.map((val, index) => (
          <NormalizeViewPending
            key={`item-${val._id}`}
            index={index}
            title={val.title}
            text={val.desc}
            onApprove={() => {
              handleApprove(val._id);
            }}
            onDelete={() => {
              alertDelete('Are you sure to delete?', (sure) => {
                if (sure) handleDelete(val._id);
              });
            }}
            onGoto={() => {
              window.open(
                `${CLIENT_URL}/${NEWSLETTER_ROUTE}/draft/${val.url}`,
                '_blank'
              );
            }}
          />
        ))}
        {(!pending || pending.length == 0) && (
          <>
            <p style={{ color: 'grey' }}>
            {/* Great job! There are no pending approvals. */}
            Everything is approved and up to date.
              {/* No Pending Approvals */}
              </p>
          </>
        )}
        {/* : All Submissions have been Reviewed and Approved */}
        <br />
        <br />
        <br />
        {/* <Row>
          <SectionTitle style={{ color: '#2e7d32e0' }}>Approved</SectionTitle>
          <H6px />
          <CenterSpan>
            <CheckCircleOutlineRoundedIcon color="success" />
          </CenterSpan>
        </Row> */}
        <Row>
          <SectionTitle>Approved</SectionTitle>
          <H4px />
          <CenterSpan>
            <CheckCircleOutlineRoundedIcon />
          </CenterSpan>
        </Row>
        <hr />
        {data?.map((val, index) => (
          <NormalizeView
            key={`item-${index}`}
            index={index}
            title={val.title}
            text={val.desc}
            onDelete={() => {
              alertDelete('Are you sure to delete?', (sure) => {
                if (sure) handleDelete(val._id);
              });
            }}
            onGoto={() => {
              window.open(
                `${CLIENT_URL}/${NEWSLETTER_ROUTE}/${val.url}`,
                '_blank'
              );
            }}
          />
        ))}
        {(!data || data.length == 0) && <>Empty</>}
        {/* ----------------------pagination ui-------------------------- */}
        <div style={{ margin: '26px 0' }}>
          <Pagination
            renderItem={(item) => {
              return (
                <PaginationItem
                  component={Link}
                  to={`${getNewsletterUrl(perPage, item.page)}`}
                  {...item}
                />
              );
            }}
            count={pageCount}
            variant="outlined"
            shape="rounded"
            page={pageNo}
            onChange={handleChange}
          />
        </div>
        {/* ------------------------------------------------ */}
      </div>
    </ReadContainer600>
  );
};
export default withDeleteHandler(MyComponent);
