import React, {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from 'react';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import placeholder from '../../assets/placeholder.png';
import { BlogMedia } from '../ProfileViewComponents';
import InputLabel from '../InputLabel';
import { TextArea } from './components';
import { SpaceBetween } from '../share';
import IconButton from '@mui/material/IconButton';
import AdvancedEditor from './helper/AdvancedEditor';
import styled from 'styled-components';
import axios from 'axios';
import { CDN_URL } from '../../config';

const SectionDiv = styled.div`
  margin-top: 8px;
`;

const StandalonSection = forwardRef((props, ref) => {
  const inputRef = useRef(null);
  const [deleteHover, setDeleteHover] = useState(false);
  const [data, setData] = useState({ html: '', image: '', imageName: '' });

  useImperativeHandle(ref, () => ({
    getData(cb) {
      // Return data to parent component
      cb({
        html: inputRef.current.value,
        text: inputRef.current.editor.getText(),
        imageName: data.imageName,
        image: data.image,
      });
    },
  }));

  useEffect(() => {
    setData({
      image: props.data.image,
      imageName: props.data.imageName,
      html: props.data.html,
    });
  }, [props.data]);

  const changeImgName = (e) => {
    setData({
      ...data,
      imageName: e.currentTarget.value,
      html: inputRef.current.value,
    });
  };

  const selectPhoto = (event) => {
    if (!event.target.files[0]) return;
    let formData = new FormData();
    formData.append('file', event.target.files[0]);
    axios
      .post(`${CDN_URL}/api/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        console.log(res.data);
        if (res.data.success) {
          setData({
            ...data,
            image: res.data.filename,
            html: inputRef.current.value,
          });
        }
      })
      .catch((err) => {
        alert(err.message);
      });
  };

  return (
    <SectionDiv
      style={
        deleteHover
          ? { border: '1px dashed #FF9494' }
          : { border: '1px dashed transparent' }
      }
    >
      <SpaceBetween>
        <div />
        <IconButton
          aria-label="delete"
          color="error"
          onMouseOver={() => setDeleteHover(true)}
          onMouseLeave={() => setDeleteHover(false)}
          onClick={() => {
            setDeleteHover(false);
            if (props.removeSection) props.removeSection();
          }}
        >
          <DeleteForeverOutlinedIcon />
        </IconButton>
      </SpaceBetween>
      <div style={{ margin: '5px 0 0px', backgroundColor: 'white' }}>
        <BlogMedia>
          {data.image ? (
            <img
              className="previous-photo"
              src={`${CDN_URL}/${data.image}`}
            ></img>
          ) : (
            <img className="placeholder" src={placeholder}></img>
          )}
          <p>Upload</p>
          <input
            className="update-photo"
            type="file"
            accept="image/*"
            onChange={selectPhoto}
          />
        </BlogMedia>
      </div>
      <InputLabel label="Image name" />
      <TextArea
        id="imageName"
        label="imageName"
        placeholder="Image name"
        rows="1"
        onChange={changeImgName}
        value={data.imageName}
      />
      <AdvancedEditor ref={inputRef} value={data.html} />
    </SectionDiv>
  );
});

export default StandalonSection;
