import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './IndexView.css';

import Dashboard from '../components/HighlightCard';
import LineChart from '../components/ui/LineChart';
import { SERVER_URL, MAIL_URL } from '../config';
import { formatPreAMPM } from '../time';
import styled from 'styled-components';

const ResponsiveTitle = styled.div`
  h1 {
    @media (max-width: 400px) {
      font-size: 1.8rem;
    }
  }
`;

const HOUR = 3600 * 1000;

const _init = {
  labels: [],
  datasets: [
    {
      label: 'Email Sent',
      data: [],
      fill: true,
      backgroundColor: 'rgba(54, 162, 235, 0.5)',
      borderColor: 'rgba(54, 162, 235, 1)',
    },
    {
      label: 'Subscription',
      data: [],
      fill: true,
      borderColor: '#742774',
    },
  ],
};

const getFormattedObj = (payload) => {
  return {
    // labels: [
    //   formatPreAMPM(5 * HOUR),
    //   formatPreAMPM(4 * HOUR),
    //   formatPreAMPM(3 * HOUR),
    //   formatPreAMPM(2 * HOUR),
    //   formatPreAMPM(HOUR),
    //   formatPreAMPM(0),
    // ],
    labels: ['6th hr', '5th hr', '4th hr', '3rd hr', '2nd hr', '1 hr'],
    labels: ['6', '5', '4', '3', '2', '1'],
    datasets: [
      {
        label: 'Email Sent',
        data: payload.notif.datasets,
        fill: false,
        // backgroundColor: 'rgba(54, 162, 235, 0.5)',
        // borderColor: 'rgba(54, 162, 235, 1)',
        borderColor: 'rgb(75, 192, 192)',
      },
      // {
      //   label: 'Subscription',
      //   data: [33, 25, 35, 51, 54, 76],
      //   fill: true,
      //   borderColor: '#742774',
      // },
    ],
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };
};

export default function IndexView() {
  const [data, setData] = useState({});
  const [stat, setStat] = useState(_init);

  useEffect(() => {
    axios
      .get(`${SERVER_URL}/api/dashboard`)
      .then((res) => {
        setData(res.data);
      })
      .catch(({ response }) => {
        console.log(response?.data?.message);
      });
    axios
      .get(`${MAIL_URL}/api/dashboard`)
      .then((res) => {
        setStat(getFormattedObj(res.data));
      })
      .catch(({ response }) => {
        console.log(response?.data?.message);
      });
  }, []);

  return (
    <>
      <ResponsiveTitle>
        <h1>Dashboard</h1>
      </ResponsiveTitle>
      <Dashboard data={data} />
      <LineChart data={stat} />
    </>
  );
}
