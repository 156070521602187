import React from 'react';
import LinkOffOutlinedIcon from '@mui/icons-material/LinkOffOutlined';

function CodeExpired() {
  return (
    <div>
      <h2>Code Expired</h2>
      <LinkOffOutlinedIcon />
      <p>Sorry, your reset code has been expired. Please request a new one.</p>
      <br />
      <br />
    </div>
  );
}

export default CodeExpired;
