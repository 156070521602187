import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { ADMIN_URL } from '../config';
import { CenterdDiv } from './styles/shared-styles';
import PrintIcon from '@mui/icons-material/Print';
import PersonAddRoundedIcon from '@mui/icons-material/PersonAddRounded';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function InputHeading({ title, btnTxt, url }) {
  const [isOpen, setIsOpen] = useState(false);
  const onClose = () => setIsOpen(!isOpen);
  const onOpen = () => setIsOpen(!isOpen);

  const handleContinue = () => {
    onClose()
    window.open(`${ADMIN_URL}/print`, '_blank');
  };

  const navigate = useNavigate();
  return (
    <div
      style={{
      }}
    >
      <h2 className="dark-text">{title}</h2>
      <hr />
      <div>
        <Button
          id="add-button"
          size="small"
          variant="contained"
          onClick={() => navigate(url ? url : 'add')}
          disableElevation
          style={{ textTransformation: 'none', marginRight: '8px' }}
          startIcon={<PersonAddRoundedIcon />}
        >
          {btnTxt ? btnTxt : 'Register'}
        </Button>
        {/* <a
          target="_blank"
          rel="noreferrer"
          href="/print"
          style={{ textDecoration: 'none' }}
        > */}
        <Button
          size="small"
          color="error"
          variant="outlined"
          disableElevation
          style={{ textTransformation: 'none' }}
          onClick={onOpen}
          startIcon={<PrintIcon />}
        >
          {btnTxt ? btnTxt : 'Print'}
        </Button>
        {/* </a> */}
      </div>
      <Modal open={isOpen} onClose={onClose}>
        <Box sx={style}>
          <Typography variant="h4" gutterBottom>
            Instructions
          </Typography>
          <Typography variant="body1" gutterBottom>
            1. Click "Print" button to navigate to the page.
          </Typography>
          <Typography variant="body1" gutterBottom>
            2. Once the page has loaded, press "<b>Ctrl + P</b>" to open the
            print dialog box.
          </Typography>
          <Typography variant="body1" gutterBottom>
            3. Choose "<b>Landscape</b>" from layout menu.
          </Typography>
          <Typography variant="body1" gutterBottom>
            4. Click the "Print" or "Save" button
          </Typography>
          <br />
          <CenterdDiv>
            <Button
              variant="outlined"
              color="error"
              onClick={handleContinue}
              disableElevation
            >
              Print
            </Button>
          </CenterdDiv>
        </Box>
      </Modal>
    </div>
  );
}
