import isDev from './stage';

export const YOUR_DOMAIN = 'https://alumni.mistipe.com';
export const DOMAIN = 'https://alumni.mistipe.com';

export const LOGO_PLACEHOLDER = 'logo.png';
export const IMAGE_PLACEHOLDER = 'image.jpg';

export const BLOG_ROUTE = 'reviews';
export const NEWSLETTER_ROUTE = 'newsletter';
export const ALUMNI_ROUTE = 'alumni';
export const EVENT_ROUTE = 'event';

// dev

// export const CLIENT_URL = isDev
//   ? 'http://localhost:3000'
//   : 'https://alumni.mistipe.com';

// export const ADMIN_URL = isDev
//   ? 'http://localhost:3001'
//   : 'https://alumni-admin.mistipe.com';

// export const SERVER_URL = isDev
//   ? 'http://localhost:5002'
//   : 'https://alumni-server.mistipe.com';

// export const MAIL_URL = isDev
//   ? 'http://localhost:5465'
//   : 'https://alumni-mail.mistipe.com';

// export const CDN_URL = isDev
//   ? 'http://localhost:5101'
//   : 'https://alumni-cdn.mistipe.com';

// export const CLIENT_URL = 'http://localhost:3000';
// export const ADMIN_URL = 'http://localhost:3001';
// export const SERVER_URL = 'http://localhost:5002';
// export const MAIL_URL = 'http://localhost:5465';
// export const CDN_URL = 'http://localhost:5101';

// prod
export const CLIENT_URL = 'https://alumni.mistipe.com';
export const ADMIN_URL = 'https://alumni-admin.mistipe.com';
export const SERVER_URL = 'https://alumni-server.mistipe.com';
export const MAIL_URL = 'https://alumni-mail.mistipe.com';
export const CDN_URL = 'https://alumni-cdn.mistipe.com';
