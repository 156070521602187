import React from "react";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router";

export default function InputHeading({ title, back }) {
  const navigate = useNavigate();
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        {back && (
          <IconButton
            onClick={() => navigate(back)}
            disableElevation
            color="primary"
            style={{ textTransformation: "none", marginRight: "10px" }}
          >
            <ArrowBackIcon />
          </IconButton>
        )}
        <h2 className="dark-text">{title}</h2>
      </div>
    </div>
  );
}
