import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router';
import styled from 'styled-components';
import TextField from '@mui/material/TextField';

import InputHeading from '../../InputHeading';
import placeholder from '../../../assets/placeholder.png';
import useAuth from '../../../contexts/AuthContext';
import { CDN_URL, SERVER_URL } from '../../../config';
import { ReadContainer600 } from '../../share';
import { ImgCenterDiv, ImgDiv } from '../HomeSectionInput';
import HomeSectionInput from '../HomeSectionInput';
import AddAction from '../helper/AddAction';
import { Space, SectionCard } from '../../styles/layout-styles';
import '../UploadProduct.css';

const FixedSection = styled.div`
  margin-top: 26px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px dashed lightgray;
  border-radius: 4px;
  color: grey;
`;

const SectionTitleTxt = styled.h3`
  color: #232323;
  font-size: 16px;
  margin-top: 26px;
`;

const BlogMedia = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border: 1px solid lightgray;
  border-radius: 4px;
  overflow: hidden;

  :hover {
    background-color: lightgrey;
    .update-photo {
      z-index: 2;
      display: block;
      justify-content: center;
      align-items: center;
    }
  }

  :active {
    border: 2px solid lightblue;
  }

  :focus {
    border: 2px solid blue;
  }

  img {
    height: 100%;
    width: 100%;
    cursor: pointer;
  }

  .update-photo {
    cursor: pointer;
    margin-top: -40px;
    position: absolute;
    display: none;
    height: 160px;
    width: 100%;
    color: lightgrey;
    background-color: #00000042;
  }
`;

const sectionSchema = {
  title: '',
  desc: '',
  image: '',
  action: {
    name: '',
    url: '',
  },
};

export default function () {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const pagename = 'home';

  const [data, setData] = useState({
    url: pagename,
    title: '',
    desc: '',
    image: '',
    sections: [{}, {}, {}, {}],
  });
  const [uploadStatus, setUploadState] = useState({
    isUploading: false,
    uploadPercentage: 0,
    fileUploading: false,
    productUploading: false,
    progressbar: false,
  });
  const [imgUpload, setUploadedImg] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    axios
      .get(`${SERVER_URL}/api/page/${pagename}`)
      .then((res) => {
        setData(res.data);
        setUploadedImg(res.data.image);
      })
      .catch(({ response }) => {
        alert(
          response?.data?.message ??
            'Error occurred while fetching data. Please try again later or contact the system administrator'
        );
      });
  }, []);

  const submitPost = (e) => {
    e.preventDefault();

    if (currentUser && !currentUser.id) {
      return alert('Please log in first.');
    }

    setLoading(true);
    setUploadState({ ...uploadStatus, productUploading: true });
    axios
      .put(`${SERVER_URL}/api/page/${data._id}`, {
        ...data,
        image: imgUpload,
      })
      .then((res) => {
        navigate('/page');
      })
      .catch(({ response }) => {
        setUploadState({ ...uploadStatus, productUploading: false });
        alert(
          response?.data?.message ??
            'Error occurred while updating data. Please try again later or contact the system administrator'
        );
      })
      .finally(() => setLoading(false));
  };

  const onDrop = (event) => {
    if (!event.target.files[0]) return;
    let formData = new FormData();
    formData.append('file', event.target.files[0]);

    const config = {
      header: { 'content-type': 'multipart/form-data' },
      onUploadProgress: (progressEvent) => {
        var percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setUploadState({
          ...uploadStatus,
          uploadPercentage: percentCompleted,
          uploadCompleted: false,
          isUploading: true,
        });
      },
    };
    axios.post(`${CDN_URL}/api/upload`, formData, config).then((res) => {
      if (res.data.success) {
        console.log(res.data);
        setUploadedImg(res.data.filename);
        setUploadState({
          ...uploadStatus,
          uploadPercentage: 0,
          uploadCompleted: true,
          isUploading: false,
        });
      } else {
        alert('failed to save the files');
      }
    });
  };

  const handleSectionChange = (i, o) => {
    const fields = data.sections;

    // We should fillup the previous array before
    const index = i;
    if (index >= fields.length) {
      const fillCount = index - fields.length + 1;
      const filledArr = new Array(fillCount).fill(sectionSchema);
      fields.push(...filledArr);
    }
    // replace | add
    fields[index] = o;

    setData({ ...data, sections: fields });
  };

  return (
    <ReadContainer600>
      <InputHeading
        title="Home page"
        btnTxt="Update"
        submit={submitPost}
        loading={loading}
        back="/page"
      />
      <form onSubmit={submitPost} className="upload-moadal">
        <SectionTitleTxt>Hero Section</SectionTitleTxt>
        <SectionCard>
          <BlogMedia>
            <ImgCenterDiv>
              <ImgDiv>
                <div className="img-musk">
                  {imgUpload ? (
                    <img
                      className="image-aspect"
                      src={`${CDN_URL}/${imgUpload}`}
                    ></img>
                  ) : (
                    <img className="image-aspect" src={placeholder}></img>
                  )}
                </div>
              </ImgDiv>
            </ImgCenterDiv>
            <input
              className="update-photo"
              type="file"
              accept="image/*"
              onChange={(event) => onDrop(event)}
            />
          </BlogMedia>
          <Space />
          <Space />
          <TextField
            id="title"
            label="Title"
            variant="outlined"
            onChange={(e) => {
              setData({ ...data, title: e.currentTarget.value });
            }}
            value={data.title}
          />
          <Space />
          <TextField
            id="desc"
            label="Description"
            multiline
            onChange={(e) => {
              setData({ ...data, desc: e.currentTarget.value });
            }}
            value={data.desc}
          />
          <AddAction
            onChange={(obj) => {
              setData({ ...data, action: obj });
            }}
            val={data.action}
          />
        </SectionCard>
        <SectionTitleTxt>Section-01</SectionTitleTxt>
        <HomeSectionInput
          sections={data.sections[0]}
          index={0}
          onChange={handleSectionChange}
        />

        <SectionTitleTxt>Section-02</SectionTitleTxt>
        <HomeSectionInput
          sections={data.sections[1]}
          index={1}
          onChange={handleSectionChange}
        />

        <FixedSection>Newsletter and Event Section</FixedSection>
        <SectionTitleTxt>Section-03</SectionTitleTxt>
        <HomeSectionInput
          sections={data.sections[2]}
          index={2}
          onChange={handleSectionChange}
        />
        <FixedSection>Mentions Section</FixedSection>
        <FixedSection>Statistics Section</FixedSection>
        <SectionTitleTxt>Section-04</SectionTitleTxt>
        <HomeSectionInput
          sections={data.sections[3]}
          index={3}
          onChange={handleSectionChange}
        />
        <FixedSection>Gallery Section</FixedSection>
        <br />
        <br />
        <br />
      </form>
    </ReadContainer600>
  );
}
