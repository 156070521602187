import React, { useEffect } from 'react';
import axios from 'axios';

import placeholder from '../../assets/placeholder.png';
import styled from 'styled-components';
import TextField from '@mui/material/TextField';
import { CDN_URL } from '../../config';
import { RowDiv } from '../share';
import AddAction from './helper/AddAction';

const Space = styled.div`
  height: 8px;
`;
const SectionCard = styled.div`
  background-color: white;
  border-radius: 6px;
  display: flex;
  flex-direction: column;

  padding: 16px;
`;

const BlogMedia = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 120px;
  width: 100%;
  border: 1px solid lightgray;
  overflow: hidden;
  border-radius: 4px;

  @media screen and (max-width: 600px) {
    width: 100%;
  }

  :hover {
    background-color: lightgrey;
    .update-photo {
      z-index: 2;
      display: block;
      justify-content: center;
      align-items: center;
    }
  }

  :active {
    border: 2px solid lightblue;
  }

  :focus {
    border: 2px solid blue;
  }

  img {
    height: 100%;
    width: 100%;
    cursor: pointer;
  }

  .update-photo {
    cursor: pointer;
    margin-top: -40px;
    position: absolute;
    display: none;
    height: 160px;
    width: 100%;
    color: lightgrey;
    background-color: #00000042;
  }
`;
export const ImgCenterDiv = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

export const ImgDiv = styled.div`
  width: 100%;
  .img-musk {
    position: relative;
    height: 0;
    padding-bottom: 56.25%; /* aspect 16:9 */
    padding-bottom: 66.66%; /* Your aspect ratio 3:2 = 2 / 3 * 100 */
  }

  .image-aspect {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  img {
    max-width: 100%;
    height: auto;
  }
`;
const emptySec = {
  title: '',
  desc: '',
  image: '',
  action: {
    name: '',
    url: '',
  },
};
export default function ({ sections: sec, index, onChange }) {
  const sections = sec || emptySec;
  useEffect(() => {}, [sections]);

  const changeFieldValue = (title, desc, image, action) => {
    onChange(index, {
      title,
      desc,
      image,
      action,
    });
  };

  const selectPhoto = (event) => {
    if (!event.target.files[0]) return;

    let formData = new FormData();
    formData.append('file', event.target.files[0]);
    axios
      .post(`${CDN_URL}/api/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        if (res.data.success) {
          onChange(index, {
            title: sections.title,
            desc: sections.desc,
            image: res.data.filename,
            action: sections.action,
          });
        }
      });
  };

  return (
    <SectionCard>
      <RowDiv>
        <div
          style={{
            backgroundColor: 'white',
            flexGrow: 1,
          }}
        >
          <BlogMedia>
            <ImgCenterDiv>
              <ImgDiv>
                <div className="img-musk">
                  {sections.image ? (
                    <img
                      className="image-aspect"
                      src={`${CDN_URL}/${sections.image}`}
                    ></img>
                  ) : (
                    <img className="image-aspect" src={placeholder}></img>
                  )}
                </div>
              </ImgDiv>
            </ImgCenterDiv>
            <input
              className="update-photo"
              type="file"
              accept="image/*"
              onChange={(event) => selectPhoto(event)}
            />
          </BlogMedia>
        </div>
        <div style={{ marginLeft: '12px', flexGrow: 1 }}>
          <TextField
            id="title"
            label="Title"
            fullWidth
            multiline
            variant="outlined"
            onChange={(e) => {
              changeFieldValue(
                e.currentTarget.value,
                sections.desc,
                sections.image,
                sections.action
              );
            }}
            defaultValue={sections.title}
            value={sections.title}
          />
          <Space />
          <TextField
            id="desc"
            fullWidth
            label="Description"
            multiline
            onChange={(e) => {
              changeFieldValue(
                sections.title,
                e.currentTarget.value,
                sections.image,
                sections.action
              );
            }}
            defaultValue={sections.desc}
            value={sections.desc}
          />
          <AddAction
            onChange={(obj) => {
              changeFieldValue(
                sections.title,
                sections.desc,
                sections.image,
                obj
              );
            }}
            val={sections.action}
          />
        </div>
      </RowDiv>
    </SectionCard>
  );
}
