import React from 'react';
import styled from 'styled-components';
import { SpaceBetween } from './share';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

const Container = styled.div`
  h3 {
    margin: 0;
    padding: 0;

    padding: 4px 0;
    font-size: 16px;
    font-weight: 600;
    color: #212529;
    line-height: 20px;
  }
  p {
    margin: 0;
    padding: 0;
    line-height: 1.4;
  }
  .mobile-normal-desc {
    display: none;
  }
  @media screen and (max-width: 892px) {
    .desktop-normal-desc {
      display: none;
    }
    .mobile-normal-desc {
      display: block;
    }
  }
`;

const DeleteWrapper = styled.div`
  display: flex;
  align-items: center;
  :hover {
    svg {
      color: #d32f2f;
    }
  }
`;
export default function NormalizeView({ title, text, onDelete, shorten }) {
  let str = text;
  if (str?.length > 240) {
    if (typeof str === 'string' || str instanceof String) {
      str = str.substring(0, 220) + '...';
    }
  }
  let mobile = text;
  if (mobile?.length > 100) {
    if (typeof mobile === 'string' || mobile instanceof String) {
      mobile = mobile.substring(0, 90) + '...';
    }
  }
  let short = text;
  if (short?.length > 35) {
    if (typeof short === 'string' || short instanceof String) {
      short = short.substring(0, 20) + '...';
    }
  }
  return (
    <SpaceBetween>
      <Container>
        <h3 className="dark-text">{title}</h3>
        {shorten ? (
          <div>
            <p className="dark-text desktop-normal-desc">{str}</p>
            <p className="dark-text mobile-normal-desc">{short}</p>
          </div>
        ) : (
          <div>
            <p className="dark-text desktop-normal-desc">{str}</p>
            <p className="dark-text mobile-normal-desc">{mobile}</p>
          </div>
        )}
      </Container>
      <DeleteWrapper>
        <IconButton onClick={onDelete}>
          <DeleteIcon />
        </IconButton>
      </DeleteWrapper>
    </SpaceBetween>
  );
}
