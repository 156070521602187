import React, { useState } from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import useAuth from '../contexts/AuthContext';
import { Link } from 'react-router-dom';
import { Row } from '../components/styles/layout-styles';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import styled from 'styled-components';

const Forgot = styled.p`
  font-weight: 700;
  color: #222;
  text-transform: uppercase;
  font-size: 0.75rem;
  line-height: 1.5;
  text-decoration: underline;
  cursor: pointer;

  :hover {
    color: #006d85;
    color: #c81631;
  }
`;

export default function LoginView() {
  const { login } = useAuth();

  const schema = yup.object({
    username: yup.string().required('Enter your username'),
    password: yup.string().trim().required("Password can't be blank"),
  });

  const handleSubmitForm = async (values, { setSubmitting, setFieldError }) => {
    setSubmitting(true);
    var response = await login(values);
    setSubmitting(false);
    if (response.data.error) {
      try {
        console.log(response.data.error.path);
        if (response.data.error.path)
          setFieldError(response.data.error.path, response.data.error.message);
      } catch (e) {}
    }
  };

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const init = {
    username: '',
    password: '',
  };
  return (
    <Box className="full-centered-container">
      <Card
        sx={{
          maxWidth: 500,
          marginLeft: 'auto',
          marginRight: 'auto',
          padding: '1rem',
        }}
      >
        <Formik
          initialValues={init}
          validationSchema={schema}
          onSubmit={handleSubmitForm}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <Container>
              <form onSubmit={handleSubmit}>
                <Typography
                  sx={{ marginTop: '1rem', fontWeight: 'bold' }}
                  component="h1"
                  variant="h4"
                >
                  Log In!
                </Typography>
                <TextField
                  sx={{ marginTop: '1.5rem' }}
                  variant="outlined"
                  fullWidth
                  size="small"
                  id="username"
                  label="Username"
                  name="username"
                  autoComplete="username"
                  value={values.username}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={
                    errors.username && touched.username ? errors.username : null
                  }
                  error={errors.username && touched.username}
                />
                <FormControl
                  sx={{ marginTop: '1.5rem' }}
                  fullWidth
                  size="small"
                  variant="outlined"
                  helperText={
                    errors.password && touched.password ? errors.password : null
                  }
                  error={errors.password && touched.password}
                >
                  <InputLabel htmlFor="password">Password</InputLabel>
                  <OutlinedInput
                    value={values.password}
                    type={showPassword ? 'text' : 'password'}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id="password"
                    label="Password"
                  />
                  {errors.password && touched.password && (
                    <FormHelperText error>{errors.password}</FormHelperText>
                  )}
                </FormControl>

                <FormControl fullWidth>
                  <Button
                    disableElevation
                    disabled={isSubmitting}
                    sx={{
                      marginTop: '1.5rem',
                      marginBottom: '1rem',
                      minWidth: '140px',
                      minHeight: '42px',
                    }}
                    variant="contained"
                    color="error"
                    type="submit"
                  >
                    {isSubmitting ? 'Please wait..' : 'Log In'}
                  </Button>
                </FormControl>
              </form>
              <br />
              <Row style={{ width: '90%' }}>
                <Link to="/forgot-password">
                  <Forgot>Forgot your password?</Forgot>
                </Link>
              </Row>
              <br />
            </Container>
          )}
        </Formik>
      </Card>
    </Box>
  );
}
