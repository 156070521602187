import React, { useState, useEffect } from 'react';
import { Card } from './styles/dashboard-styles';
import {
  HighlightContainer,
  HighlightCount,
  HighlightCountContainer,
  HighlightTitle,
  HighlightWrapper,
} from './styles/highlight-styles';

export default function FeaturedInfo({ data }) {
  const [status, setStatus] = useState();

  useEffect(() => {
    let _data = {};

    // Initialize data if null
    if (!data) {
      _data.noticeCount = 0;
      _data.newsletterCount = 0;
      _data.batchCount = 0;
      _data.alumniCount = 0;
    } else _data = data;

    let _status = [
      {
        title: 'Notice',
        dollar: _data.noticeCount,
        gradient: ['rgba(152, 82, 240, 1) ', 'rgba(90, 1, 201, 1)'],
        subTitle: 'Daily active user',
      },
      // {
      //   title: 'Events',
      //   dollar: _data.weekly,
      //   gradient: ['rgba(31, 208, 112, 1)', 'rgba(1, 169, 68, 1)'],
      //   subTitle: 'Weekly active user',
      // },
      {
        title: 'Newsletters',
        dollar: _data.newsletterCount,
        gradient: ['rgba(243, 144, 52, 1)', 'rgba(255, 42, 39, 1))'],
        subTitle: 'Total user',
      },
      {
        title: 'Batch',
        dollar: _data.batchCount,
        gradient: ['rgba(243, 144, 52, 1)', 'rgba(255, 42, 39, 1))'],
        subTitle: 'Total user',
      },
      {
        title: 'Alumni',
        dollar: _data.alumniCount,
        gradient: ['rgba(0, 149, 235, 1)', 'rgba(0, 59, 210, 1)'],
        subTitle: 'Monthly active user',
      },
    ];

    setStatus(_status);
  }, [data]);

  return (
    <HighlightContainer>
      <HighlightWrapper>
        {status &&
          status.map((val, pos) => (
            <Card
              style={{
                backgroundColor: 'white',
                backgroundImage: 'none',
                color:'#232323'
              }}
              key={`hightlight-card-no${pos}`}
            >
              <HighlightTitle>{val.title}</HighlightTitle>
              <HighlightCountContainer>
                <HighlightCount>{val.dollar}</HighlightCount>
              </HighlightCountContainer>
            </Card>
          ))}
      </HighlightWrapper>
    </HighlightContainer>
  );
}
