import Button from '@mui/material/Button';
import styled from 'styled-components';
import { HoriSpace6px } from '../share';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { useState } from 'react';

const Container = styled.div`
  h4 {
    margin: 0;
    padding: 0;
    line-height: 1.5;
  }
  p {
    margin: 0;
    margin-top: 2px;
    padding: 0;
    line-height: 1.4;
  }
  .d-block {
    display: block;
  }

  @media screen and (max-width: 892px) {
    h3 {
      font-size: 16px;
    }
  }
`;

const BtnDiv = styled.div`
  display: flex;
  justify-content: center;
`;

export default function NormalizeView({
  name,
  username,
  email,
  onDelete,
  onEdit,
  role,
  superAccount,
  isSuperAdmin,
}) {
  const [deleting, setDeleting] = useState(false);
  return (
    <tr>
      <td align="right" className="center-align">
        <ManageAccountsIcon />
      </td>
      <td className="full-width">
        <Container>
          <h4 className="dark-text">{name}</h4>
          <p>
            username: <span className="username-color">{username}</span>
          </p>
          <p>
            role: <span className="role-color">{role}</span>
          </p>
          <p>{email}</p>
        </Container>
      </td>
      {!isSuperAdmin || (superAccount && <td></td>)}
      {isSuperAdmin && !superAccount && (
        <td>
          <BtnDiv>
            <Button
              variant="outlined"
              size="small"
              color="error"
              onClick={() => {
                setDeleting(true);
                onDelete();
              }}
              disabled={deleting}
              style={{ width: '60px' }}
              className="btn1-margin"
            >
              Delete
            </Button>
            <HoriSpace6px />
            {onEdit && (
              <Button
                variant="outlined"
                size="small"
                onClick={onEdit}
                style={{ width: '60px' }}
              >
                Edit
              </Button>
            )}
          </BtnDiv>
        </td>
      )}
    </tr>
  );
}
