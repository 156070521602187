import styled from 'styled-components';

export const Space = styled.div`
  height: 8px;
`;
export const SectionCard = styled.div`
  background-color: white;
  border-radius: 6px;
  display: flex;
  flex-direction: column;

  padding: 16px;
`;

export const Row = styled.div`
  display: flex;
  width: 100%;
`;
export const SpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
`;

