import styled from '@emotion/styled';

export const TextArea = styled.textarea`
  padding: 4px 6px;
  width: 100%;
  box-sizing: border-box;
  text-align: start;
  font-size: 1rem;
  font-family: '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif';
`;
export const Input = styled.input`
  padding: 4px 6px;
  width: 100%;
  box-sizing: border-box;
  text-align: start;
  font-size: 1rem;
  font-family: '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif';
`;
export const TextAreaValue = styled.textarea`
  padding: 4px 6px;
  width: 200px;
  text-align: start;
  font-size: 1rem;
  font-family: '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif';
`;
export const Example = styled.p`
  padding: 4px 2px;
  font-size: 14px;
  color: ${ props => props.color ? props.color : 'grey' };
  font-family: '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif';
`;
