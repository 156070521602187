import styled from 'styled-components';

export const Main = styled.main`
  padding: 2rem 0 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 892px) {
    padding: 12px 12px 0;
  }
`;

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
`;

export const WidthContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 892px;
`;

export const InputContainer = styled.div`
  max-width: 600px;
  width: 100%;
  margin: auto;
`;
export const BodyContainer = styled.div`
  padding-top: 16px;
  width: 100%;

  @media screen and (max-width: 892px) {
    padding-top: 0;
    margin: auto;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;
export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;
export const CenterdDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const Body = styled.div`
  display: flex;
  padding: 0 26px;
  flex-grow: 1;
  flex-direction: column;
  @media screen and (max-width: 892px) {
    padding: 0;
  }
`;

export const Footer = styled.footer`
  padding: 10px 0;
  @media screen and (max-width: 892px) {
    padding: 4px 0;
  }
`;

export const FooterText = styled.p`
  padding: 0;
`;

export const WidthContent = styled.div`
  max-width: 975px;
  width: 100%;
`;
export const ContainerBox = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

export const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  borderRadius: '4px',
  boxShadow: 12,
  padding: '15px',
};

export const CenterSpan = styled.span`
  display: inline-flex;
  justify-content: center;
  align-items: center;
`;

export const SectionTitle = styled.h2`
  margin: 0;
    padding: 0;
`;
