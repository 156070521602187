import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { SERVER_URL } from '../config';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  /* Compare table module */
  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    cursor: pointer;
    /* width: 8.5in; */
    max-width: 816px;
    min-width: 1024px;
  }

  td,
  th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 12px;
  }

  tr:nth-child(even) {
    background-color: rgba(0, 0, 0, 0.03);
  }

  tr:hover {
    background-color: rgba(0, 0, 0, 0.03);
  }

  .head {
    font-weight: 700;
    font-size: 16px;
    text-transform: uppercase;
    color: #d50000;
  }
  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
  }

  td,
  th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 12px;
    font-size: 14px;
    font-family: 'Inter', sans-serif;
    line-height: 1.42857;
  }

  tr:nth-child(even) {
    background-color: rgba(0, 0, 0, 0.03);
  }

  .head {
    font-weight: 700;
    font-size: 16px;
    color: black;
    padding: 0;
  }

  table {
    border-collapse: collapse;
    cursor: pointer;
  }

  td,
  th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 12px;
    width: 25%;
  }
  .td-value {
    width: 80%;
  }

  tr:nth-child(even) {
    background-color: rgba(0, 0, 0, 0.03);
  }

  table {
    border: 1px solid lightgrey;
    border-collapse: separate;
    border-radius: 8px;
    border-spacing: 0px;
  }

  th,
  td {
    padding: 8px 10px;
    text-align: left;
    vertical-align: top;
  }

  tr:nth-child(even) {
    /* background-color: #dddddd; */
    background-color: rgba(0, 0, 0, 0.03);
  }

  tr:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }

  .center-td {
    text-align: center;
  }

  .head-compare {
    font-weight: bold;
    font-size: 16px;
    line-height: 1.5;
    padding: 10px;
    color: black;
  }

  table,
  th,
  td {
    border: 1px solid black;
    border-collapse: collapse;
  }

  .not-break {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

function createData(
  mistId,
  name,
  currentJob,
  degree,
  email,
  phone,
  batch,
  passingYear
) {
  if (!mistId) return null;
  return {
    mistId,
    name,
    currentJob,
    degree,
    email,
    phone,
    batch,
    passingYear,
  };
}

export default function () {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch();
  }, []);

  const fetch = async () => {
    try {
      const res = await axios.get(`${SERVER_URL}/api/user?list=ase`);
      const batchs = [];
      res.data?.map((val) => {
        const item = createData(
          val.mistId,
          val.name,
          val.currentJob,
          val.degree,
          val.email,
          val.phone,
          val.batch,
          val.passingYear
        );

        // Group alumni by admission year
        const _year = val.year.toString();
        if (batchs[_year]) batchs[_year].push(item);
        else batchs[_year] = [item];
      });
      setData(batchs);
    } catch ({ response }) {
      alert(
        response?.data?.message ??
          'Error occurred while fetching data. Please try again later or contact the system administrator'
      );
      setData([]);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading ? (
        <>Loading...</>
      ) : (
        <Wrapper>
          {data.map((batch, i) => (
            <table style={{ margin: '26px 0', pageBreakAfter: 'always' }}>
              <tr>
                <td
                  component="th"
                  scope="row"
                  colspan="8"
                  className="head-compare"
                >
                  MIST IPE BATCH {i}
                </td>
              </tr>
              <tr>
                <td>Roll</td>
                <td>Name</td>
                <td>Current Job</td>
                <td>Last Academic Degree</td>
                <td>Email</td>
                <td>Phone</td>
                <td>Batch</td>
                <td>Passing Year</td>
              </tr>
              {batch.map((user) => {
                return (
                  <tr>
                    <td>{user.mistId}</td>
                    <td>{user.name}</td>
                    <td>{user.currentJob}</td>
                    <td>{user.degree}</td>
                    <td>{user.email}</td>
                    <td>{user.phone}</td>
                    <td className="not-break">{user.batch}</td>
                    <td>{user.passingYear}</td>
                  </tr>
                );
              })}
            </table>
          ))}
        </Wrapper>
      )}
    </>
  );
}
