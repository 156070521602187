import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import useAuth from '../../contexts/AuthContext.js';
import { useLocation } from 'react-router-dom';
import SettingsIcon from '@mui/icons-material/Settings';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import CollectionsIcon from '@mui/icons-material/Collections';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import WebIcon from '@mui/icons-material/Web';
import WindowIcon from '@mui/icons-material/Window';
import SupportIcon from '@mui/icons-material/Support';
import PortraitIcon from '@mui/icons-material/Portrait';
import GroupsRoundedIcon from '@mui/icons-material/GroupsRounded';
import AddToPhotosRoundedIcon from '@mui/icons-material/AddToPhotosRounded';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import {
  SideBarItemText,
  TabItemText,
  SideBar,
  TabItem,
  SideBarItemSelected,
  SideBarItem,
  SideBarTabItem,
  SideBarTabItemSelected,
  SideBarItemSelectedText,
  TabSideBar,
} from './SidebarComponents';
import './Sidebar.css';

export const navs = [
  {
    name: 'Dashboard',
    path: 'dashboard',
    redirect: '/dashboard',
    icon: <WindowIcon />,
  },
  {
    name: 'Notice',
    path: 'notice',
    redirect: '/notice',
    icon: <ContentPasteIcon />,
  },
  {
    name: 'Newsletter',
    path: 'newsletter',
    redirect: '/newsletter',
    icon: <NewspaperIcon />,
  },

  {
    name: 'Batch',
    path: 'batch',
    redirect: '/batch',
    icon: <AddToPhotosRoundedIcon />,
  },
  {
    name: 'Alumni',
    path: 'alumni',
    redirect: '/alumni',
    icon: <GroupsRoundedIcon />,
  },
  {
    name: 'Gallery',
    path: 'gallery',
    redirect: '/gallery',
    icon: <CollectionsIcon />,
  },
  {
    name: 'Mention',
    path: 'mention',
    redirect: '/mention',
    icon: <PortraitIcon />,
  },
  {
    name: 'Page',
    path: 'page',
    redirect: '/page',
    icon: <WebIcon />,
  },
  {
    name: 'Help Request',
    path: 'help',
    redirect: '/help',
    icon: <SupportIcon />,
  },
  {
    name: 'Admin users',
    path: 'admin',
    redirect: '/admin',
    icon: <ManageAccountsIcon />,
  },
  {
    name: 'Settings',
    path: 'settings',
    redirect: '/settings',
    icon: <SettingsIcon />,
  },
];

const getTabPos = (endPoint) => {
  for (let i = 0; i < navs.length; i++) {
    if (navs[i].path === endPoint) return i;
  }
};
const endPoint = (pathname) => {
  return pathname.split('/').slice(-1)[0].split('?')[0];
};

export default function Sidebar() {
  let { pathname } = useLocation();
  const { logout, currentUser } = useAuth();
  const [sidebarPosition, setSidebarPos] = useState(
    getTabPos(getTabPos(endPoint(pathname)))
  );
  const navigate = useNavigate();
  const handleSideBarClick = (index, laptop) => {
    if (navs[index].path === 'logout') logout();
    navigate(navs[index].redirect);
    setSidebarPos(index);
  };

  return (
    <>
      <SideBar className="laptop-sidebar">
        <div
          style={{
            padding: '10px 0 16px 0px',
            borderBottom: '.1px solid grey',
          }}
        >
          {navs.map((val, i) =>
            i === sidebarPosition ? (
              <SideBarItemSelected
                key={val.name}
                onClick={() => handleSideBarClick(i, true)}
              >
                {val.icon}
                <SideBarItemSelectedText>{val.name}</SideBarItemSelectedText>
              </SideBarItemSelected>
            ) : (
              <SideBarItem
                key={val.name}
                onClick={() => handleSideBarClick(i, true)}
              >
                {val.icon}
                <SideBarItemText>{val.name}</SideBarItemText>
              </SideBarItem>
            )
          )}
        </div>
        <div
          style={{
            padding: '16px 20px',
          }}
        >
          <p style={{ color: '#909090', fontSize: '12px' }}>
            {`© ${new Date().getFullYear()} MIST-IPE Alumni`}
          </p>
        </div>
      </SideBar>

      <TabSideBar
        className="tab-sidebar"
        style={{
          position: 'sticky',
          top: '40',
          overflow: 'hidden',
        }}
      >
        <div
          style={{
            padding: '0 0 16px 0',
            borderBottom: '.2px solid #e7e7e7',
          }}
        >
          {navs.map((val, i) =>
            i === sidebarPosition ? (
              <SideBarTabItemSelected
                key={val.name}
                onClick={() => handleSideBarClick(i)}
              >
                <TabItem>
                  {val.icon}
                  <TabItemText>{val.name}</TabItemText>
                </TabItem>
              </SideBarTabItemSelected>
            ) : (
              <SideBarTabItem
                key={val.name}
                onClick={() => handleSideBarClick(i)}
              >
                <TabItem>
                  {val.icon}
                  <TabItemText>{val.name}</TabItemText>
                </TabItem>
              </SideBarTabItem>
            )
          )}
        </div>
      </TabSideBar>
    </>
  );
}
