import styled from "styled-components";

export const RemoveItemBtn = styled.div`
  position: absolute;
  right: 2px;
  top: 2px;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;

  background: rgba(255, 255, 255, 0.06);
  border: 1px solid rgba(255, 255, 255, 0.32);

  border-radius: 50%;
  padding: 2px;
  svg {
    color: lightgrey;
    font-size: 16px;
  }
  :hover {
    svg {
      color: red;
    }
    border: 0.5px solid red;
  }
  cursor: pointer;
`;
export const UploadedItem = styled.div`
  position: relative;
  height: 120px;
  width: 130px;
  /* margin-top: 52px; */
  display: flex;
  overflow: hidden;
  border: 0.5px dashed lightgray;
  align-items: center;
  justify-content: center;
`;