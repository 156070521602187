import { SERVER_URL, CDN_URL } from './config';

const getJpeg = (filename) => {
  if (!filename) return '';
  return filename.split('.')[0] + '.jpeg';
};

// cdn has medium folder where we will get
// jpeg format image of main file
export const getMediumImage = (filename) => {
  if (!filename) return '';

  return `${CDN_URL}/medium/${getJpeg(filename)}`;
};

export const roundDecimal = (number, decimalPlaces) => {
  const factorOfTen = Math.pow(10, decimalPlaces);
  return Math.round(number * factorOfTen) / factorOfTen;
};

export const NUMBER_REGX = /^[0-9\b]+$/;
export const MoneyFormatInt = (_int) => {
  let formatter = new Intl.NumberFormat();
  return formatter.format(_int);
};

export const dayMonth = (timestamp) => {
  const formatThis = new Date(timestamp);

  return {
    day: formatThis.getDate(),
    month: month[formatThis.getMonth()],
  };
};
export const noticeDate = (date) => {
  const formatThis = new Date(date);

  return (
    month[formatThis.getMonth()] +
    ' ' +
    formatThis.getDate() +
    ', ' +
    formatThis.getFullYear()
  );
};
function get_nth_suffix(number) {
  switch (number) {
    case 1:
    case 21:
    case 31:
      return number + 'st';
    case 2:
    case 22:
      return number + 'nd';
    case 3:
    case 23:
      return number + 'rd';
    default:
      return number + 'th';
  }
}
const month = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];
export const date = (timestamp) => {
  const formatThis = new Date(timestamp);

  return (
    formatThis.getDate() +
    '-' +
    month[formatThis.getMonth()] +
    '-' +
    formatThis.getFullYear()
  );
};
export const dateSlash = (timestamp) => {
  const formatThis = new Date(timestamp);

  return (
    formatThis.getDate() +
    '/' +
    month[formatThis.getMonth()] +
    '/' +
    formatThis.getFullYear()
  );
};

export const isValidBatchName = (batchName) => {
  // "IPE-" + at least two digits from 01 to 999
  const batchPattern = /^IPE-(0[1-9]|[1-9][0-9]{1,2})$/;
  return batchPattern.test(batchName);
};

// same for both url & api
export const getFilterUrl = (
  searchTxt,
  brandId,
  perPage,
  pageNo,
  sort,
  sortTime
) =>
  `posts?search=${searchTxt}&brand=${brandId}&pageNo=${pageNo}&perPage=${perPage}&sort=${sort}&sortTime=${sortTime}`;

export const getRecentPostApi = (perPage, pageNo) =>
  `posts/recent?pageNo=${pageNo}&perPage=${perPage}`;

export const getRecentPostUrl = (perPage, pageNo) =>
  `post?pageNo=${pageNo}&perPage=${perPage}`;

export const getRecentPostUrlDash = (perPage, pageNo) =>
  `posts?pageNo=${pageNo}&perPage=${perPage}`;

export const getBlogUrl = (perPage, pageNo) =>
  `?pageNo=${pageNo}&perPage=${perPage}`;

export const getNewsletterUrl = (perPage, pageNo) =>
  `?pageNo=${pageNo}&perPage=${perPage}`;

export const getEventUrl = (perPage, pageNo) =>
  `?pageNo=${pageNo}&perPage=${perPage}`;

export const getBlogApi = (perPage, pageNo) =>
  `blog?pageNo=${pageNo}&perPage=${perPage}`;

export const getNewsletterApi = (perPage, pageNo) =>
  `newsletter?pageNo=${pageNo}&perPage=${perPage}`;

export const getBatchApi = (pageNo) => `batch/${pageNo}?include=user`;

export const getBatchUrl = (pageNo) => `?batch=${pageNo}&include=user`;

export const getEventApi = (perPage, pageNo) =>
  `event?pageNo=${pageNo}&perPage=${perPage}`;

export const getReviewUrl = (perPage, pageNo) =>
  `?pageNo=${pageNo}&perPage=${perPage}`;

export const getReviewApi = (perPage = 10, pageNo = 1) =>
  `reviews?pageNo=${pageNo}&perPage=${perPage}`;
