import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

import useAuth from '../../contexts/AuthContext';
import AddHeader from '../AddHeader';
import SubmitLoading from '../SubmitLoading';
import { CDN_URL, SERVER_URL } from '../../config';
import { ReadContainer600, SpaceBetween } from '../share';
import { noticeDate } from '../../utils';
import withDeleteHandler from '../ui/DeleteWrapper';

const NoticeWrapper = styled.div`
  padding: 4px 0;
  /* border-bottom: 1px dotted #e2e2e2; */
  padding: 5px 0;
  h2 {
    font-size: 30px;
    color: #051145;
    margin: 0;
    padding: 4px 0;

    font-size: 16px;
    font-weight: 600;
    color: #212529;
    line-height: 20px;

    :hover {
      color: #bf1430 !important;
      text-decoration: underline;
    }
  }
  p {
    margin: 0;
    padding: 0;

    color: #bf1430;
    font-size: 13px;
    font-weight: 600;
    line-height: 20px;
  }
`;

const DeleteWrapper = styled.div`
  :hover {
    svg {
      color: #d32f2f;
    }
  }
`;

const MyComponent = ({ alertDelete }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const {
    currentUser: { accessToken },
  } = useAuth();

  const fetch = () => {
    axios
      .get(`${SERVER_URL}/api/notice`)
      .then((res) => {
        setData(res.data.payload);
      })
      .catch(({ response }) => {
        alert(
          response?.data?.message ??
            'Error occurred while fetching data. Please try again later or contact the system administrator'
        );
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetch();
  }, []);

  const handleDelete = (id) => {
    setLoading(true);
    axios
      .delete(`${SERVER_URL}/api/notice/${id}`, {
        headers: {
          'x-access-token': accessToken,
        },
      })
      .then((res) => {
        fetch();
      })
      .catch(({ response }) => {
        alert(response?.data?.message ?? 'Unable to delete this notice.');
        setLoading(false);
      });
  };

  return (
    <ReadContainer600>
      <AddHeader title="Notice" />
      <hr />
      <SubmitLoading open={loading} />
      <div id="notice-list">
        {data?.map((val) => (
          <SpaceBetween key={val._id}>
            <NoticeWrapper>
              <a
                key={`link-bottom-${val.title}`}
                target="_blank"
                rel="noreferrer"
                href={`${CDN_URL}/${val.url}`}
                style={{ textDecoration: 'none', color: 'black' }}
              >
                <h2>{val.title}</h2>
              </a>
              <p>{noticeDate(val.updatedAt)}</p>
            </NoticeWrapper>
            <DeleteWrapper>
              <IconButton
                id="delete-button"
                onClick={() => {
                  alertDelete('Are you sure to delete?', (sure) => {
                    if (sure) handleDelete(val._id);
                  });
                }}
              >
                <DeleteIcon />
              </IconButton>
            </DeleteWrapper>
          </SpaceBetween>
        ))}
      </div>
    </ReadContainer600>
  );
};
export default withDeleteHandler(MyComponent);
