import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import AddHeader from '../AddBtnClick';
import SubmitLoading from '../SubmitLoading';
import { ReadContainer600 } from '../share';
import {
  SortableItem,
  SortableContainer,
  arrayMove,
} from '../ui/FooterReorder';
import { Row } from '../styles/layout-styles';
import { SERVER_URL } from '../../config';
import withDeleteHandler from '../ui/DeleteWrapper';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '1px solid #e2e2e2',
  boxShadow: 24,
  p: 4,
};

const MyComponent = ({ alertDelete }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({ social: [], help: [], site: [] });
  const [input, setInput] = useState({ name: '', url: '' });
  const [errors, setError] = useState({ name: false, url: false });
  const [type, setType] = useState('');
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  const fetch = () => {
    axios
      .get(`${SERVER_URL}/api/footer`)
      .then((res) => {
        const social = [];
        const help = [];
        const site = [];
        res.data?.map((val) => {
          if (val.type == 'social') social.push(val);
          if (val.type == 'help') help.push(val);
          if (val.type == 'site') site.push(val);
        });
        setData({ social, help, site });
      })
      .catch((e) => {})
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetch();
  }, []);

  const handleDelete = (id) => {
    setLoading(true);
    axios
      .delete(`${SERVER_URL}/api/footer/${id}`)
      .then((res) => {
        fetch();
      })
      .catch((err) => {
        alert('Unable to delete this link.');
      })
      .finally(() => setLoading(false));
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const update = arrayMove(data.social, oldIndex, newIndex);
    const map = update.map((val, i) => ({ _id: val._id, position: i }));
    axios
      .put(`${SERVER_URL}/api/footer/position`, map)
      .then((res) => {})
      .catch(({ response }) => {})
      .finally(() => fetch());
  };
  const onSortHelp = ({ oldIndex, newIndex }) => {
    const update = arrayMove(data.help, oldIndex, newIndex);
    const map = update.map((val, i) => ({ _id: val._id, position: i }));
    axios
      .put(`${SERVER_URL}/api/footer/position`, map)
      .then((res) => {})
      .catch(({ response }) => {})
      .finally(() => fetch());
  };
  const onSortSite = ({ oldIndex, newIndex }) => {
    const update = arrayMove(data.site, oldIndex, newIndex);
    const map = update.map((val, i) => ({ _id: val._id, position: i }));
    axios
      .put(`${SERVER_URL}/api/footer/position`, map)
      .then((res) => {})
      .catch(({ response }) => {})
      .finally(() => fetch());
  };

  const addLink = (name, url, type) => {
    const _data = {
      url: url,
      name: name,
      type: type,
      position: data[type].length,
    };
    axios
      .post(`${SERVER_URL}/api/footer`, _data)
      .then((res) => {
        alert('Footer link added sucessfully!');
      })
      .catch(({ response }) => {})
      .finally(() => fetch());
  };

  const handleSubmit = () => {
    if (!input.name) {
      return setError({ ...errors, name: true });
    }
    if (!input.url) {
      return setError({ ...errors, url: true });
    }
    // console.log(type, input.name, input.url);
    addLink(input.name, input.url, type);
    setInput({ name: '', url: '' });
    setOpen(false);
  };
  const addSocialLink = () => {
    setType('social');
    setOpen(true);
  };
  const addHelpLink = () => {
    setType('help');
    setOpen(true);
  };
  const addSiteLink = () => {
    setType('site');
    setOpen(true);
  };
  return (
    <ReadContainer600>
      <SubmitLoading open={loading} />
      {/* <h1 style={{ color: 'grey' }}>Footer</h1> */}
      <br />
      <AddHeader title="Social Link" onClick={addSocialLink} />
      <hr />
      <SortableContainer onSortEnd={onSortEnd} useDragHandle>
        {data?.social?.map((val, index) => {
          val.title = val.name;
          val.desc = val.url;
          return (
            <SortableItem
              key={`item-${index}`}
              index={index}
              value={val}
              shorten={true}
              onDelete={() => {
                alertDelete('Are you sure to delete?', (sure) => {
                  if (sure) {
                    setData({
                      ...data,
                      social: data.social.filter((item) => item._id != val._id),
                    });
                    handleDelete(val._id);
                  }
                });
              }}
            />
          );
        })}
      </SortableContainer>
      <br />
      <AddHeader title="Get Help" onClick={addHelpLink} />
      <hr />
      <SubmitLoading open={loading} />
      <SortableContainer onSortEnd={onSortHelp} useDragHandle>
        {data?.help?.map((val, index) => {
          val.title = val.name;
          val.desc = val.url;
          return (
            <SortableItem
              key={`item-${index}`}
              index={index}
              value={val}
              shorten={true}
              onDelete={() => {
                alertDelete('Are you sure to delete?', (sure) => {
                  if (sure) {
                    setData({
                      ...data,
                      social: data.social.filter((item) => item._id != val._id),
                    });
                    handleDelete(val._id);
                  }
                });
              }}
            />
          );
        })}
      </SortableContainer>
      <br />
      <AddHeader title="This Site" onClick={addSiteLink} />
      <hr />
      <SubmitLoading open={loading} />
      <SortableContainer onSortEnd={onSortSite} useDragHandle>
        {data?.site?.map((val, index) => {
          val.title = val.name;
          val.desc = val.url;
          return (
            <SortableItem
              key={`item-${index}`}
              index={index}
              value={val}
              shorten={true}
              onDelete={() => {
                alertDelete('Are you sure to delete?', (sure) => {
                  if (sure) {
                    setData({
                      ...data,
                      social: data.social.filter((item) => item._id != val._id),
                    });
                    handleDelete(val._id);
                  }
                });
              }}
            />
          );
        })}
      </SortableContainer>
      <div>
        <Modal open={open} onClose={handleClose}>
          <Box sx={style}>
            <h2>Add Link</h2>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              label="Name"
              value={input.name}
              onChange={(e) => {
                setError({ ...errors, name: false });
                setInput({ ...input, name: e.target.value });
              }}
              error={errors.name}
            />
            <TextField
              sx={{ marginTop: '.5rem' }}
              variant="outlined"
              size="small"
              fullWidth
              label="URL"
              value={input.url}
              onChange={(e) => {
                setError({ ...errors, url: false });
                setInput({ ...input, url: e.target.value });
              }}
              error={errors.url}
            />
            <br />
            <br />

            <Row>
              <Button
                disableElevation
                variant="contained"
                onClick={handleSubmit}
              >
                Save
              </Button>
              <Button style={{ color: 'grey' }} onClick={handleClose}>
                Cancel
              </Button>
            </Row>
          </Box>
        </Modal>
      </div>
    </ReadContainer600>
  );
};
export default withDeleteHandler(MyComponent);
