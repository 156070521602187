import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import axios from 'axios';
import styled from 'styled-components';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import useAuth from '../../contexts/AuthContext';
import AddHeader from '../AddHeader';
import SubmitLoading from '../SubmitLoading';
import NormalizeView from '../NormalizeViewImg';
import { SERVER_URL } from '../../config';
import { getMediumImage } from '../../utils';
import { ReadContainer600, GridContainerDual } from '../share';
import withDeleteHandler from '../ui/DeleteWrapper';

const Wrapper = styled.div`
  margin-bottom: 20px;
`;

const ImgWrapper = styled.div`
  position: relative;
  max-width: 320px;

  p {
    margin: 0;
    padding: 0;
    font-size: 13px;
    font-weight: 600;
    line-height: 20px;
  }
  .action-wrapper {
    display: flex;
    position: absolute;
    top: 8px;
    right: 8px;
  }
`;

const DeleteWrapper = styled.div`
  border-radius: 50%;
  background-color: #ececec7d;

  :hover {
    svg {
      color: #d32f2f;
    }
  }
`;
const EditWrapper = styled.div`
  margin-right: 4px;

  border-radius: 50%;
  background-color: #ececec7d;

  :hover {
    svg {
      color: black;
    }
  }
`;

const MyComponent = ({ alertDelete }) => {
  const [loading, setLoading] = useState(true);
  const { currentUser } = useAuth();
  const [data, setData] = useState([]);
  const navigate = useNavigate();

  const fetch = () => {
    axios
      .get(`${SERVER_URL}/api/mention?include=all`)
      .then((res) => {
        setData(res.data);
      })
      .catch(({ response }) => {
        alert(
          response?.data?.message ??
            'Error occurred while fetching data. Please try again later or contact the system administrator'
        );
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetch();
  }, []);

  const handleDelete = (id) => {
    setLoading(true);
    axios
      .delete(`${SERVER_URL}/api/mention/${id}`, {
        headers: {
          'x-access-token': currentUser.accessToken,
        },
      })
      .then((res) => fetch())
      .catch(({ response }) => {
        alert(
          response?.data?.message ??
            'Error occurred while fetching data. Please try again later or contact the system administrator'
        );
      })
      .finally(() => setLoading(false));
  };

  return (
    <ReadContainer600>
      <AddHeader title="Mention" />
      <hr />
      <SubmitLoading open={loading} />
      <div>
        {data?.map((val, index) => (
          <NormalizeView
            key={`item-${val.name}`}
            index={index}
            title={val.name}
            image={getMediumImage(val.image)}
            text={val.short}
            onEdit={() => {
              navigate(`edit/${val._id}`);
            }}
            onDelete={() => {
              alertDelete('Are you sure to delete?', (sure) => {
                if (sure) handleDelete(val._id);
              });
            }}
          />
          // <Wrapper>
          //   <ImgWrapper>
          //     <img src={getMediumImage(val.image)} />
          //     <div className="action-wrapper">
          //       <EditWrapper>
          //         <IconButton onClick={() => navigate(`edit/${val._id}`)}>
          //           <EditIcon />
          //         </IconButton>
          //       </EditWrapper>
          //       <DeleteWrapper>
          //         <IconButton
          //           onClick={() => {
          //             alertDelete('Are you sure to delete?', (sure) => {
          //               if (sure) handleDelete(val._id);
          //             });
          //           }}
          //         >
          //           <DeleteIcon />
          //         </IconButton>
          //       </DeleteWrapper>
          //     </div>
          //   </ImgWrapper>
          //   <p>{val.name}</p>
          // </Wrapper>
        ))}
      </div>
    </ReadContainer600>
  );
};
export default withDeleteHandler(MyComponent);
