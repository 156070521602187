import React, { useEffect, useState } from 'react';
import axios from 'axios';
import useAuth from '../contexts/AuthContext';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Formik } from 'formik';
import * as yup from 'yup';
import { useNavigate } from 'react-router';
import { SERVER_URL } from '../config';
import ProfileEditPass from './ProfileEditPass';
import './edit/UploadProduct.css';
import styled from 'styled-components';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '1px solid #e2e2e2',
  boxShadow: 24,
  p: 4,
};

const ChangePass = styled.p`
  display: inline-flex;
  color: rgb(211, 47, 47);
  font-weight: 500;
  cursor: pointer;

  :hover {
    text-decoration: underline;
  }
`;

const Wrapper = styled.div`
  padding: 16px 26px;
  width: 100%;
  max-width: 520px;

  h2 {
    margin: 0;
    padding: 6px 0;
  }
`;

const schema = yup.object({
  name: yup.string().required("Name can't be empty"),
  username: yup.string().required('Unique username is required!'),
  email: yup.string().required('Please provide your email address.'),
});

export default function ProfileEditForm() {
  const navigate = useNavigate();
  const [userData, setUser] = useState();
  const { currentUser } = useAuth();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    fetchAdmin();
  }, []);

  const fetchAdmin = () => {
    axios
      .get(`${SERVER_URL}/api/admin/${currentUser.id}`)
      .then((res) => {
        setUser(res.data);
      })
      .catch(({ response }) => {
        alert(
          response?.data?.message ??
            'Error occurred while fetching data. Please try again later or contact the system administrator'
        );
      });
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const updateUser = async (data) => {
    try {
      await axios.put(`${SERVER_URL}/api/admin/${currentUser.id}`, data, {
        headers: { 'x-access-token': currentUser.accessToken },
      });
      alert('Information updated successfully!');

      // email will be collected from mail server
      // nothing to work

      fetchAdmin();
      navigate(`/profile`);
    } catch (err) {
      alert('An error occured while saving data! Please try again later.');
    }
  };

  const handleSubmitForm = async (values, { setSubmitting }) => {
    setSubmitting(true);
    var data = {
      name: values.name,
      email: values.email,
    };
    await updateUser(data);
    setSubmitting(false);
  };

  return userData ? (
    <Wrapper>
      <h2>Admin</h2>
      <div>
        <Formik
          initialValues={userData}
          validationSchema={schema}
          onSubmit={handleSubmitForm}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <form
              style={{ display: 'flex', flexDirection: 'column' }}
              onSubmit={handleSubmit}
            >
              <TextField
                sx={{ marginTop: '1.5rem' }}
                variant="outlined"
                fullWidth
                id="name"
                label="Name"
                name="name"
                autoComplete="name"
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={errors.name && touched.name ? errors.name : null}
                error={errors.name && touched.name}
              />
              <TextField
                sx={{ marginTop: '1.5rem' }}
                variant="outlined"
                fullWidth
                disabled
                label="Username"
                defaultValue={values.username}
              />
              <br />
              <TextField
                id="email"
                label="Email"
                value={values.email}
                defaultValue={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={errors.email && touched.email ? errors.email : null}
                error={errors.email && touched.email}
              />
              <br />

              <div>
                <Button
                  variant="contained"
                  type="submit"
                  disableElevation
                  size="medium"
                >
                  UPDATE
                </Button>
              </div>
            </form>
          )}
        </Formik>
      </div>
      <br />
      <br />
      <ChangePass onClick={handleOpen}>Change password</ChangePass>
      <div>
        <Modal open={open} disableBackdropClick disableEscapeKeyDown>
          <Box sx={style}>
            <ProfileEditPass
              accessToken={currentUser.accessToken}
              data={userData}
              onClose={handleClose}
            />
          </Box>
        </Modal>
      </div>
    </Wrapper>
  ) : (
    <span>loading...</span>
  );
}
