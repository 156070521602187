import React, { useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { SERVER_URL } from '../config';

const AuthContext = React.createContext();

export default function useAuth() {
  return useContext(AuthContext);
}
export const baseUrl = `${SERVER_URL}/api/admin`;

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();
  const [loading, setLoading] = useState(true);
  const [cookies, setCookie] = useCookies(['admin']);

  const handle = (token, expr) => {
    setCookie(process.env.REACT_APP_COOKIE_AUTH_ADMIN_TOKEN, token, {
      path: '/',
      maxAge: expr,
    });
  };

  async function createAdmin(data) {
    const { mistipeadminauthtoken: token } = cookies;
    return await axios
      .post(`${baseUrl}`, data, {
        headers: {
          'x-access-token': token,
        },
      })
      .then((res) => res)
      .catch(({ response }) => response);
  }

  async function login(data) {
    return axios
      .post(`${baseUrl}/login`, data)
      .then((res) => {
        handle(res.data.accessToken, 2592000);
        setCurrentUser(res.data);
        return res;
      })
      .catch(({ response }) => response);
  }

  function logout() {
    const request = axios
      .delete(`${baseUrl}/logout/${currentUser.accessToken}`)
      .then((response) => {
        handle('', 0);
        setCurrentUser(response?.data);
      });

    return request;
  }

  useEffect(() => {
    const { mistipeadminauthtoken: token } = cookies;
    axios
      .get(`${baseUrl}/auth`, {
        headers: {
          'x-access-token': token,
        },
      })
      .then((res) => {
        setCurrentUser({ ...res.data, accessToken: token });
        setLoading(false);
      })
      .catch(({ response }) => {
        console.log(response?.data?.message);
        setLoading(false);
      });
  }, []);

  const value = {
    currentUser,
    login,
    createAdmin,
    logout,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
