import React, { useState } from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import axios from 'axios';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import { Link } from 'react-router-dom';
import MarkEmailReadOutlinedIcon from '@mui/icons-material/MarkEmailReadOutlined';

import { Row } from '../components/styles/layout-styles';
import NewPasswordInput from '../components/NewPasswordInput';
import CodeExpired from '../components/CodeExpired';
import styled from 'styled-components';
import { SERVER_URL } from '../config';

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-top: 4px;
  h2 {
    margin: 0;
    padding: 6px 0;
  }
  .icon-sp {
    width: 10px;
  }
`;

const Forgot = styled.p`
  font-weight: 700;
  color: #222;
  text-transform: uppercase;
  font-size: 0.75rem;
  line-height: 1.5;
  text-decoration: underline;
  cursor: pointer;

  :hover {
    color: #006d85;
    color: #c81631;
  }
`;

export default function LoginView() {
  const [email, setEmail] = useState('');
  const [emailSent, setEmailSent] = useState(false);

  const [code, setCode] = useState('');
  const [codeVerified, setCodeVerified] = useState(false);
  const [codeExpired, setCodeExpired] = useState(false);

  const [loading, setLoading] = useState(false);

  const handleSubmitForm = async (values, { setSubmitting, setFieldError }) => {
    setSubmitting(true);
    setEmail(values.email.trim());
    try {
      await axios.post(`${SERVER_URL}/api/admin/forgot-password`, {
        email: values.email.trim(),
      });
    } catch (err) {
      if (err.response?.data?.message) {
        setFieldError(err.response?.data?.message);
      } else setFieldError(err.message);

      setSubmitting(false);
      return;
    }
    setSubmitting(false);
    setEmailSent(true);
  };

  const verifyCode = () => {
    axios
      .get(`${SERVER_URL}/api/admin/access/${code}`)
      .then((res) => {
        if (res.data.access === 'authorized') {
          setCodeVerified(true);
        } else {
          setCodeExpired(true);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const init = {
    email: '',
  };
  const schema = yup.object({
    email: yup.string().trim().required("Email can't be empty"),
  });

  return (
    <Box className="full-centered-container">
      <Card
        sx={{
          maxWidth: 500,
          marginLeft: 'auto',
          marginRight: 'auto',
          padding: '1rem',
        }}
      >
        <Formik
          initialValues={init}
          validationSchema={schema}
          onSubmit={handleSubmitForm}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <Container>
              {emailSent ? (
                <div>
                  {codeVerified ? (
                    <NewPasswordInput email={email} code={code} />
                  ) : (
                    <div>
                      {codeExpired ? (
                        <CodeExpired />
                      ) : (
                        <div>
                          <Header>
                            <h2>Email Sent</h2>
                            <div className="icon-sp" />
                            <MarkEmailReadOutlinedIcon />
                          </Header>
                          <p>
                            A code has been sent to your email address. If you
                            don't get an email within a few minutes, please
                            re-try.
                          </p>
                          <br />
                          <TextField
                            variant="outlined"
                            size="small"
                            id="code"
                            label="Code"
                            name="code"
                            value={code}
                            onChange={(e) => setCode(e.target.value)}
                          />
                          <br />
                          <Button
                            disabled={!code || loading}
                            disableElevation
                            sx={{
                              marginTop: '1rem',
                              marginBottom: '1.5rem',
                              minWidth: '140px',
                              minHeight: '42px',
                            }}
                            variant="contained"
                            color="error"
                            onClick={verifyCode}
                          >
                            {isSubmitting ? 'Please wait..' : 'Verify Code'}
                          </Button>
                        </div>
                      )}

                      <Forgot onClick={() => setEmailSent(false)}>
                        Resend again
                      </Forgot>
                    </div>
                  )}
                </div>
              ) : (
                <form onSubmit={handleSubmit}>
                  <Typography
                    sx={{ marginTop: '1rem', fontWeight: 'bold', mb: '6px' }}
                    component="h1"
                    variant="h4"
                  >
                    Password Reset
                  </Typography>
                  <p>
                    Enter your admin email address to get password reset code.
                  </p>
                  <TextField
                    sx={{ marginTop: '1.5rem' }}
                    variant="outlined"
                    fullWidth
                    size="small"
                    id="email"
                    label="Email"
                    name="email"
                    autoComplete="email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={
                      errors.email && touched.email ? errors.email : null
                    }
                    error={errors.email && touched.email}
                  />

                  <FormControl fullWidth>
                    <Button
                      disabled={isSubmitting}
                      disableElevation
                      sx={{
                        marginTop: '1.5rem',
                        marginBottom: '1rem',
                        minWidth: '140px',
                        minHeight: '42px',
                      }}
                      variant="contained"
                      color="error"
                      type="submit"
                    >
                      {isSubmitting ? 'Please wait..' : 'Send Code'}
                    </Button>
                  </FormControl>
                  <br />
                  <Row style={{ width: '90%' }}>
                    <Link to="/login">
                      <Forgot>Back to Login</Forgot>
                    </Link>
                  </Row>
                  <br />
                </form>
              )}
            </Container>
          )}
        </Formik>
      </Card>
    </Box>
  );
}
