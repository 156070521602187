import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Filler,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import './chart.css';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Filler,
  Title,
  Tooltip,
  Legend
);

export default function ({ data }) {
  return (
    <div className="chart">
      <h3 className="chartTitle">Notification Sent</h3>
      <Line
        data={data}
        options={{
          scales: {
            y: {
              beginAtZero: true,
              suggestedMax: 500,
              title: {
                display: true,
                text: 'Email sent count'
              }
            },
            x:{
              title: {
                display: true,
                text: 'Last 6 Hours'
              }
            }
          },
        }}
      />
    </div>
  );
}
