import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import axios from 'axios';
import InputHeading from '../InputHeading';
import CircularProgress from '@mui/material/CircularProgress';
import InputLabel from '../InputLabel';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import Dropzone from 'react-dropzone';
import useAuth from '../../contexts/AuthContext';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { TextArea } from './components';
import { CDN_URL, SERVER_URL } from '../../config';
import './UploadProduct.css';
import { ReadContainer600 } from '../share';
import { Space, SectionCard, Row } from '../styles/layout-styles';

export default function ({ edit, handleClose }) {
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  const [data, setData] = useState({
    title: '',
    desc: '',
    url: '',
  });
  const [uploadStatus, setUploadState] = useState({
    isUploading: false,
    uploadPercentage: 0,
    fileUploading: false,
    productUploading: false,
    progressbar: false,
  });
  const [loading, setLoading] = useState(false);

  const submitPost = (e) => {
    e.preventDefault();

    if (currentUser && !currentUser.id) {
      return alert('Please log in first.');
    }
    
    setLoading(true);
    setUploadState({ ...uploadStatus, productUploading: true });
    axios
      .post(`${SERVER_URL}/api/notice`, data, {
        headers: {
          'x-access-token': currentUser.accessToken,
        },
      })
      .then((response) => {
        navigate('/notice');
        setLoading(false);
      })
      .catch(({ response }) => {
        setUploadState({ ...uploadStatus, productUploading: false });
        alert(
          response.err == undefined ? 'Internal Server Error' : response.err
        );
        setLoading(false);
      });
  };

  const selectPhoto = (files) => {
    if (!files[0]) return;
    let formData = new FormData();
    formData.append('file', files[0]);
    axios
      .post(`${CDN_URL}/api/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        if (res.data.success) {
          setData({ ...data, url: res.data.filename });
        }
      });
  };
  const selectAttachment = (event) => {
    if (!event.target.files[0]) return;
    let formData = new FormData();
    formData.append('file', event.target.files[0]);
    axios
      .post(`${CDN_URL}/api/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        if (res.data.success) {
          setData({ ...data, url: res.data.filename });
        }
      });
  };
  return (
    <ReadContainer600>
      <InputHeading
        title="Add notice"
        btnTxt="Publish"
        submit={submitPost}
        loading={loading}
        back="/notice"
      />
      <h2>Notice</h2>
      <div>
        <div style={{ marginTop: '6px' }}>
          <TextField
            fullWidth
            id="title"
            label="Notice title"
            variant="standard"
            onChange={(e) => {
              setData({ ...data, title: e.currentTarget.value });
            }}
            value={data.title}
          />
        </div>
        <br />
        <Row>
          <Button disableElevation variant="outlined" component="label">
            Attachment
            <input
              id="input-file"
              type="file"
              hidden
              onChange={selectAttachment}
            />
          </Button>
          <p>{data.url}</p>
        </Row>
        <br />
      </div>
    </ReadContainer600>
  );
}
