import styled from 'styled-components';
import React from 'react';
import { RowDiv, RowDivAlignCenter, SpaceBetween } from './share';
import EditIcon from '@mui/icons-material/Edit';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import IconButton from '@mui/material/IconButton';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Tooltip } from '@mui/material';

const Wrapper = styled.div`
  padding: 2px 8px;
  width: 100%;
  padding-left: 0;

  svg {
    height: 20px;
    width: 20px;
    color: #364452;
  }

  h3 {
    margin: 0;
    padding: 0;
    font-size: 1rem;
    margin-left: 8px;
    cursor: pointer;
    :hover {
      text-decoration: underline;
    }
    margin: 0;
    padding: 0;

    padding: 4px 0;
    font-size: 16px;
    font-weight: 600;
    color: #212529;
    line-height: 20px;
  }
  .delete-btn {
    :hover {
      svg {
        color: rgb(211, 47, 47);
      }
    }
  }
  .row {
    display: flex;
  }
`;

export default function NormalizeView({ title, onEdit, onGoto, onDelete }) {
  return (
    <Wrapper>
      <SpaceBetween>
        <RowDivAlignCenter>
          {/* <EditIcon /> */}
          <h3 onClick={onEdit}>
            {title.charAt(0).toUpperCase() + title.slice(1)}
          </h3>
        </RowDivAlignCenter>
        <div className="row">
          {onDelete && (
            <>
              <Tooltip title="Delete this page">
                <IconButton
                  variant="contained"
                  size="small"
                  onClick={onDelete}
                  disableElevation
                  className="delete-btn"
                >
                  <DeleteOutlineIcon />
                </IconButton>
              </Tooltip>
              <div style={{ width: '6px' }} />
            </>
          )}
          <Tooltip title="Go to this page">
            <IconButton
              variant="contained"
              size="small"
              onClick={onGoto}
              disableElevation
            >
              <OpenInNewIcon />
            </IconButton>
          </Tooltip>
        </div>
      </SpaceBetween>
    </Wrapper>
  );
}
