import styled from "styled-components";

export const CenterdDiv = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const RowDiv = styled.div`
  display: flex;
  width: 100%;
`;
export const Row = styled.div`
  display: flex;
`;
export const RowDivAlignCenter = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

export const RowDivAlignCenterBetween = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

export const HoriSpace6px = styled.div`
  width: 6px;
`;

export const VirSpace6px = styled.div`
  height: 6px;
`;
export const VirSpace3px = styled.div`
  height: 2px;
`;

export const SpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const WidthContent = styled.div`
  max-width: 975px;
  width: 100%;
`;
export const ContainerBox = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;
export const ReadContainer = styled.div`
  max-width: 800px;
`;
export const ReadContainer600 = styled.div`
  max-width: 600px;
`;
export const Root = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fafafa;
`;
export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
`;
export const Body = styled.div`
  display: flex;
  width: 100%;
  padding: 0 26px;
  flex-grow: 1;
  flex-direction: column;

  @media screen and (max-width: 892px) {
    padding: 0 12px;
  }
`;

export const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  borderRadius: "4px",
  boxShadow: 12,
  padding: "15px",
};

export const GridContainerDual = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;

  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
`;

export const GridContainerTripple = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin: 0 auto;
  max-width: 1200px;

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 480px) {
    grid-template-columns: 1fr;
  }
`;
