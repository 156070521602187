import React from 'react';
import InputLabel from '../../InputLabel';

import { TextArea } from '../components';
import styled from 'styled-components';

const SectionDiv = styled.div`
  margin-top: 8px;
`;

export default function ({ val, onChange }) {
  const changeFieldValue = (name, url) => {
    onChange({ name, url });
  };

  return (
    <SectionDiv>
      <InputLabel label="Button Name" />
      <TextArea
        id="name"
        label="name"
        placeholder="Action Name"
        rows="1"
        onChange={(e) => {
          changeFieldValue(e.currentTarget.value, val?.url);
        }}
        value={val?.name}
      />
      <InputLabel label="URL" />
      <TextArea
        id="url"
        label="url"
        placeholder="URL"
        rows="2"
        onChange={(e) => {
          changeFieldValue(val?.name, e.currentTarget.value);
        }}
        value={val?.url}
      />
    </SectionDiv>
  );
}
