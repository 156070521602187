import styled from 'styled-components';
import React from 'react';
import { RowDiv, RowDivAlignCenter, SpaceBetween } from './share';
import EditIcon from '@mui/icons-material/Edit';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import IconButton from '@mui/material/IconButton';
import { Tooltip } from '@mui/material';
const Wrapper = styled.div`
  padding: 2px 8px;
  width: 100%;
  padding-left: 0;

  svg {
    height: 20px;
    width: 20px;
    color: #364452;
  }

  h3 {
    margin: 0;
    padding: 0;
    line-height: 1.5;
    font-size: 1rem;
    margin-left: 8px;
    cursor: pointer;
    :hover {
      text-decoration: underline;
    }

    margin: 0;
    padding: 0;

    padding: 4px 0;
    font-size: 16px;
    font-weight: 600;
    color: #212529;
    line-height: 20px;
  }
`;

export default function NormalizeView({ title, onEdit, onGoto }) {
  return (
    <Wrapper>
      <SpaceBetween>
        <RowDivAlignCenter>
          {/* <EditIcon /> */}
          <h3 onClick={onEdit}>
            {title.charAt(0).toUpperCase() + title.slice(1)}
          </h3>
        </RowDivAlignCenter>
        <Tooltip title="Go to this page">
          <IconButton
            variant="contained"
            size="small"
            onClick={onGoto}
            disableElevation
          >
            <OpenInNewIcon />
          </IconButton>
        </Tooltip>
      </SpaceBetween>
    </Wrapper>
  );
}
