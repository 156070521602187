import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Button } from '@mui/material';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function BasicModal({ show: error, modalClosed }) {
  const [open, setOpen] = React.useState(false);
  React.useEffect(() => {
    setOpen(!!error);
  }, [error]);

  const handleClose = () => {
    setOpen(false);
    modalClosed(false);
  };
  const handleContinue = () => {
    modalClosed(true);
    setOpen(false);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      id="confirm-modal"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Warning!
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          {error}
        </Typography>
        <Button id="cancel-button" onClick={handleClose}>
          Cancel
        </Button>
        <Button id="confirm-button" onClick={handleContinue}>
          Continue
        </Button>
      </Box>
    </Modal>
  );
}
