import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router';
import * as yup from 'yup';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { useNavigate } from 'react-router';
import { Formik, Form, Field } from 'formik';

import InputHeading from '../InputHeading2';
import useAuth from '../../contexts/AuthContext';
import { SERVER_URL } from '../../config';
import { ReadContainer600 } from '../share';
import './UploadProduct.css';

export default function ({ edit }) {
  const navigate = useNavigate();

  const { currentUser } = useAuth();
  const { id } = useParams();
  const [loading, setLoading] = useState(true);

  const [admin, setAdmin] = useState({
    name: '',
    username: '',
    email: '',
  });

  const fetchAdmin = () => {
    axios
      .get(`${SERVER_URL}/api/admin/${id}`)
      .then((res) => {
        console.log(res.data);
        setAdmin(res.data);
      })
      .catch(({ response }) =>
        alert(
          response?.data?.message ??
            'Error occurred while fetching data. Please try again later or contact the system administrator'
        )
      )
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (edit) fetchAdmin();
    else setLoading(false);
  }, []);

  const handleSubmitForm = async (values, { setSubmitting }) => {
    if (currentUser && !currentUser.accessToken) {
      return alert('Please log in first.');
    }
    setSubmitting(true);
    setLoading(true);

    const adminData = values;

    if (edit) {
      axios
        .put(`${SERVER_URL}/api/admin/${id}`, adminData, {
          headers: {
            'x-access-token': currentUser.accessToken,
          },
        })
        .then((res) => navigate('/admin'))
        .catch(({ response }) =>
          alert(
            response?.data?.message ??
              'Error occurred while updating user. Please try again later or contact the system administrator'
          )
        )
        .finally(() => setLoading(false));
    } else {
      axios
        .post(`${SERVER_URL}/api/admin`, adminData, {
          headers: {
            'x-access-token': currentUser.accessToken,
          },
        })
        .then((res) => navigate('/admin'))
        .catch(({ response }) => {
          alert(
            response?.data?.message ??
              'Error occurred while fetching data. Please try again later or contact the system administrator'
          );
          console.log(response);
        })
        .finally(() => setLoading(false));
    }
  };

  const validationSchema = yup.object().shape({
    name: yup.string(),
    username: yup.string().required('Provie an unique username'),
    email: yup
      .string()
      .required("Provide an unique email address")
      .email('Invalid email format. e.g example@gmail.com'),
  });

  return (
    <ReadContainer600>
      <InputHeading
        title={edit ? 'Edit Admin' : 'Add New Admin'}
        back="/admin"
      />

      {loading ? (
        <>Loading..</>
      ) : (
        <Formik
          initialValues={admin}
          validationSchema={validationSchema}
          onSubmit={handleSubmitForm}
        >
          {({ errors, isSubmitting, touched }) => (
            <Form>
              <br />
              <Field
                id="name"
                name="name"
                as={TextField}
                label="Name"
                variant="outlined"
                margin="normal"
                fullWidth
                error={touched.name && Boolean(errors.name)}
                helperText={touched.name && errors.name}
              />
              <Field
                id="username"
                name="username"
                as={TextField}
                label="Username"
                disabled={edit}
                variant="outlined"
                margin="normal"
                fullWidth
                type="text"
                error={touched.username && Boolean(errors.username)}
                helperText={touched.username && errors.username}
              />
              <Field
                id="email"
                name="email"
                as={TextField}
                label="Email address"
                variant="outlined"
                margin="normal"
                fullWidth
                error={touched.email && Boolean(errors.email)}
                helperText={touched.email && errors.email}
              />
              <Button
                disableElevation
                disabled={isSubmitting}
                sx={{
                  marginTop: '1rem',
                  marginBottom: '1rem',
                  minWidth: '140px',
                  minHeight: '42px',
                }}
                variant="contained"
                color="primary"
                type="submit"
                id="submit-button"
              >
                {isSubmitting ? 'Please wait..' : edit ? 'Update' : 'Create'}
              </Button>
            </Form>
          )}
        </Formik>
      )}
    </ReadContainer600>
  );
}
