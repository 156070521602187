import React, { useState } from 'react';
import InputLabel from '../../InputLabel';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';

import { SpaceBetween } from '../../share';
import { TextArea } from '../components';
import styled from 'styled-components';

const SectionDiv = styled.div`
  margin-top: 8px;
`;

export default function ({ val, onChange }) {
  const [deleteHover, setDeleteHover] = useState(false);

  const addField = () => {
    onChange(val.actions.length, { name: '', url: '' });
  };

  const changeFieldValue = (index, name, url) => {
    onChange(index, { name, url });
  };

  const removeSection = (index) => {
    onChange(index, null);
  };

  return (
    <div>
      {val.actions?.map((data, pos) => {
        return (
          <SectionDiv
            style={
              deleteHover
                ? { border: '1px dashed #FF9494' }
                : { border: '1px dashed transparent' }
            }
          >
            <SpaceBetween>
              <h4>Action Button - {pos + 1}</h4>
              <IconButton
                aria-label="delete"
                color="error"
                onMouseOver={() => setDeleteHover(true)}
                onMouseLeave={() => setDeleteHover(false)}
                onClick={() => {
                  setDeleteHover(false);
                  removeSection(pos);
                }}
                style={{ height: '48px', width: '48px' }}
              >
                <DeleteForeverOutlinedIcon />
              </IconButton>
            </SpaceBetween>

            <InputLabel label="Name" />
            <TextArea
              id="name"
              label="name"
              placeholder="Action Name"
              rows="1"
              onChange={(e) => {
                changeFieldValue(pos, e.currentTarget.value, data.url);
              }}
              value={data.name}
            />
            <InputLabel label="URL" />
            <TextArea
              id="url"
              label="url"
              placeholder="URL"
              rows="1"
              onChange={(e) => {
                changeFieldValue(pos, data.name, e.currentTarget.value);
              }}
              value={data.url}
            />
          </SectionDiv>
        );
      })}
      <br />
      <Button
        variant="outlined"
        disableElevation
        size="small"
        onClick={addField}
      >
        + Action Button
      </Button>
    </div>
  );
}
