import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import axios from 'axios';
import NormalizeView from '../NormalizeBatch';
import AddHeader from '../AddHeader';
import { SERVER_URL, CLIENT_URL } from '../../config';
import SubmitLoading from '../SubmitLoading';
import { ReadContainer600, GridContainerDual } from '../share';
import withDeleteHandler from '../ui/DeleteWrapper';
import useAuth from '../../contexts/AuthContext';

const AllBatch = ({ alertDelete }) => {
  const [loading, setLoading] = useState(true);
  const { currentUser } = useAuth();
  const [data, setData] = useState([]);
  const navigate = useNavigate();

  const fetch = () => {
    axios
      .get(`${SERVER_URL}/api/batch`)
      .then((res) => setData(res.data))
      .catch(({ response }) => {
        alert(
          response?.data?.message ??
            'Error occurred while fetching data. Please try again later or contact the system administrator'
        );
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetch();
  }, []);

  const handleDelete = (batch) => {
    setLoading(true);
    axios
      .delete(`${SERVER_URL}/api/batch/${batch}`, {
        headers: {
          'x-access-token': currentUser.accessToken,
        },
      })
      .then((res) => fetch())
      .catch((error) => {
        alert(
          error.response?.err === undefined
            ? 'Internal Server Error'
            : error.response.err
        );
        setLoading(false);
      });
  };

  return (
    <ReadContainer600>
      <AddHeader title="Batch" />
      <hr />
      <SubmitLoading open={loading} />
      {/* <GridContainerDual> */}
      <div id="batch-list">
        {data.map((val, index) => (
          <NormalizeView
            key={`item-${val.batch}`}
            index={index}
            title={val.title}
            text={val.short}
            onEdit={() => {
              navigate(`edit/${val.batch}`);
            }}
            onDelete={() => {
              alertDelete('Are you sure to delete?', (sure) => {
                if (sure) handleDelete(val.batch);
              });
            }}
            url={() => {
              window.open(`${CLIENT_URL}/batch?ipe=${val.batch}`, '_blank');
            }}
          />
        ))}
      </div>
      {/* </GridContainerDual> */}
    </ReadContainer600>
  );
};

export default withDeleteHandler(AllBatch);
