import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router';
import axios from 'axios';
import Dropzone from 'react-dropzone';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import CircularProgress from '@mui/material/CircularProgress';
import CloseIcon from '@mui/icons-material/Close';

import useAuth from '../../contexts/AuthContext';
import InputHeading from '../InputHeading';
import TextField from '@mui/material/TextField';
import { CDN_URL, SERVER_URL } from '../../config';
import './UploadProduct.css';
import { ReadContainer600 } from '../share';
import { RemoveItemBtn, UploadedItem } from '../styles/upload-styles';
import './UploadProduct.css';
import { roundDecimal } from '../../utils';

export default function ({ edit }) {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const { id } = useParams();
  const [data, setData] = useState({
    tags: [],
    caption: '',
    image: '',
  });

  const [loading, setLoading] = useState(false);
  const [uploadStatus, setUploadState] = useState({
    isUploading: false,
    uploadPercentage: 0,
    fileUploading: false,
    postUploading: false,
    progressbar: false,
  });
  const [uploadedImg, setUploadedFiles] = useState('');

  useEffect(() => {
    if (edit) {
      axios
        .get(`${SERVER_URL}/api/gallery/${id}`)
        .then((res) => {
          setData(res.data);
          setUploadedFiles(res.data.image);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [edit]);

  const onDrop = (files) => {
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      addInput(file);
    }
  };
  const addInput = (file) => {
    if (!file) return;
    let formData = new FormData();
    formData.append('file', file);

    setUploadState({ ...uploadStatus, fileUploading: true });
    const config = {
      header: { 'content-type': 'multipart/form-data' },
      onUploadProgress: (progressEvent) => {
        setUploadState({
          ...uploadStatus,
          uploadPercentage: roundDecimal((progressEvent / 1) * 100, 2),
          progressbar: true,
        });
      },
    };

    axios
      .post(`${CDN_URL}/api/image`, formData, config)
      .then((res) => {
        if (res.data.success) {
          setUploadedFiles(res.data.filename);
        } else {
          alert('failed to save the files');
        }
        setUploadState({
          ...uploadStatus,
          uploadPercentage: 0,
          uploadCompleted: true,
          isUploading: false,
        });
        setUploadState({ ...uploadStatus, fileUploading: false });
      })
      .catch((err) => {
        setUploadState({ ...uploadStatus, fileUploading: false });
      });
  };

  const submitPost = async (e) => {
    e.preventDefault();

    if (!uploadedImg) {
      return alert('Please upload a photo!');
    }

    setLoading(true);
    setUploadState((prevState) => ({
      ...prevState,
      productUploading: true,
    }));

    const requestData = {
      ...data,
      image: uploadedImg,
    };
    const requestConfig = {
      headers: {
        'x-access-token': currentUser.accessToken,
      },
    };

    try {
      if (edit) {
        await axios.put(
          `${SERVER_URL}/api/gallery/${data._id}`,
          requestData,
          requestConfig
        );
      } else {
        await axios.post(
          `${SERVER_URL}/api/gallery`,
          requestData,
          requestConfig
        );
      }

      navigate('/gallery');
    } catch (error) {
      alert(
        error.response?.err === undefined
          ? 'Internal Server Error'
          : error.response.err
      );
    } finally {
      setLoading(false);
      setUploadState((prevState) => ({
        ...prevState,
        productUploading: false,
      }));
    }
  };

  const deletePhoto = () => {
    axios
      .delete(`${CDN_URL}/api/${uploadedImg}`)
      .then((res) => {
        if (res.data.success) {
          setUploadedFiles('');
        } else alert(JSON.stringify(res.data));
      })
      .catch((e) => {
        alert(JSON.stringify(e));
      });
  };
  return (
    <ReadContainer600>
      <InputHeading
        title="Add Photos"
        btnTxt="Publish"
        submit={submitPost}
        loading={loading}
        back="/gallery"
      />
      <div>
        <br />
        {uploadedImg ? (
          <UploadedItem key={uploadedImg}>
            <img
              key={`${uploadedImg}`}
              style={{ width: '100%' }}
              alt="thumbnail"
              src={`${CDN_URL}/${uploadedImg}`}
            />
            <RemoveItemBtn onClick={deletePhoto}>
              <CloseIcon />
            </RemoveItemBtn>
          </UploadedItem>
        ) : (
          <div id="drop-zone-wrapper">
            <Dropzone
              data-cy="drag-and-drop"
              onDrop={onDrop}
              multiple={false}
              maxSize={800000000}
            >
              {({ getRootProps, getInputProps }) => (
                <div
                  className="hover-lightgrey"
                  style={{
                    width: '130px',
                    height: '120px',
                    display: 'flex',
                    border: '.5px dashed lightgray',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  {...getRootProps()}
                >
                  <input {...getInputProps()} id="input-file" />

                  <div
                    style={{
                      height: '100%',
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    {uploadStatus.isUploading && (
                      <CircularProgress
                        disableShrink
                        size="24px"
                        style={{ color: 'lightgrey' }}
                      />
                    )}
                    {uploadStatus.isUploading && (
                      <p style={{ color: 'grey', fontSize: '12px' }}>
                        `${uploadStatus.uploadPercentage}%`
                      </p>
                    )}

                    {!uploadStatus.isUploading && (
                      <div
                        style={{
                          fontSize: '12px',
                          display: 'flex',
                          flexDirection: 'row',
                          fontWeight: 'bold',
                          color: 'rgb(99, 91, 255)',
                          alignItems: 'center',
                          cursor: 'pointer',
                        }}
                      >
                        <ArrowUpwardIcon
                          style={{ fontSize: '16px', marginRight: '2px' }}
                        />
                        <p style={{ margin: '0' }}>Upload</p>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </Dropzone>
          </div>
        )}
        <div style={{ marginTop: '8px' }}>
          <TextField
            fullWidth
            id="caption"
            label="Image caption"
            variant="standard"
            onChange={(e) => {
              setData({ ...data, caption: e.currentTarget.value });
            }}
            value={data.caption}
          />
        </div>
      </div>
    </ReadContainer600>
  );
}
