import React, { useEffect, useState, useRef, createRef } from 'react';
import { useNavigate } from 'react-router';
import Button from '@mui/material/Button';
import { useParams } from 'react-router';
import axios from 'axios';
import InputHeading from '../InputHeading';
import InputLabel from '../InputLabel';
import { BlogMedia } from '../ProfileViewComponents';
import placeholder from '../../assets/placeholder.png';
import useAuth from '../../contexts/AuthContext';
import { TextArea } from './components';
import { CDN_URL, SERVER_URL, DOMAIN } from '../../config';
import './UploadProduct.css';
import { ReadContainer600 } from '../share';
import AdvancedEditor from './helper/AdvancedEditor';
import StandalonSection from './StandalonSection';

export default function ({ edit }) {
  const { currentUser } = useAuth();
  const { id } = useParams();
  const navigate = useNavigate();
  const inputRef = useRef(null);

  const [data, setData] = useState({
    title: '',
    desc: '',
    name: '',
    subTitle: '',
    department: '',
    email: '',
    delta: '',
    html: '',
  });
  const [uploadStatus, setUploadState] = useState({
    isUploading: false,
    uploadPercentage: 0,
    fileUploading: false,
    productUploading: false,
    progressbar: false,
  });
  const [imgUpload, setUploadedImg] = useState(null);
  const [loading, setLoading] = useState(false);
  const [htmlContent, setHtmlContent] = useState('');
  const [sections, setSections] = useState([]);
  const [elRefs, setElRefs] = useState([]);

  useEffect(() => {
    if (edit) {
      axios
        .get(`${SERVER_URL}/api/page/${id}`)
        .then((res) => {
          setData(res.data);
          setHtmlContent(res.data.html);
          setUploadedImg(res.data.image);
          if (res.data.sections) setSections(res.data.sections);
        })
        .catch((e) => {
          console.log(e);
          alert(e.message);
        });
    }
  }, [edit]);

  useEffect(() => {
    // add or remove refs
    setElRefs((elRefs) =>
      Array(sections.length)
        .fill()
        .map((_, i) => elRefs[i] || createRef())
    );
  }, [sections.length]);

  const changeTitle = (event) => {
    setData({ ...data, title: event.currentTarget.value });
  };

  const submitPost = async (e) => {
    e.preventDefault();

    if (currentUser && !currentUser.id) {
      return alert('Please log in first.');
    }

    setLoading(true);
    setUploadState({ ...uploadStatus, productUploading: true });

    // Upload instance
    const uploadData = {
      ...data,
      html: inputRef.current.value,
      desc: inputRef.current.editor.getText(),
      image: imgUpload,
      sections: getSectionData(),
    };
    try {
      if (edit) {
        await axios.put(`${SERVER_URL}/api/page/${data._id}`, uploadData);
      } else {
        await axios.post(`${SERVER_URL}/api/page`, uploadData);
      }

      navigate('/page');
    } catch (error) {
      alert('Failed while uploading your post. Please try again later.');
    } finally {
      setLoading(false);
      setUploadState((prevState) => ({
        ...prevState,
        productUploading: false,
      }));
    }
  };

  const onDrop = (event) => {
    if (!event.target.files[0]) return;
    let formData = new FormData();
    formData.append('file', event.target.files[0]);

    const config = {
      header: { 'content-type': 'multipart/form-data' },
      onUploadProgress: (progressEvent) => {
        var percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setUploadState({
          ...uploadStatus,
          uploadPercentage: percentCompleted,
          uploadCompleted: false,
          isUploading: true,
        });
      },
    };
    axios.post(`${CDN_URL}/api/upload`, formData, config).then((res) => {
      if (res.data.success) {
        console.log(res.data);
        setUploadedImg(res.data.filename);
        setUploadState({
          ...uploadStatus,
          uploadPercentage: 0,
          uploadCompleted: true,
          isUploading: false,
        });
      } else {
        alert('failed to save the files');
      }
    });
  };

  const sectionStateChange = (removeIndex, addNew) => {
    const updateSection = [];
    let i = 0;

    // Update section data from state
    elRefs.forEach((el) => {
      el.current.getData((sectionData) => {
        console.log('sectionData', sectionData);
        if (i != removeIndex) {
          updateSection.push(sectionData);
        }
        i++;
      });
    });

    // Add new section
    if (addNew) {
      updateSection.push({ delta: '', html: '', image: '', imageName: '' });
    }
    setSections(updateSection);
  };
  const getSectionData = () => {
    const updateSection = [];
    let i = 0;
    elRefs.forEach((el) => {
      el.current.getData((sectionData) => {
        updateSection.push(sectionData);
        i++;
      });
    });
    return updateSection;
  };
  const addSection = () => {
    sectionStateChange(-1, true);
  };
  const removeSection = (index) => {
    sectionStateChange(index, null);
  };

  return (
    <ReadContainer600>
      <InputHeading
        title={edit ? 'Edit Page' : 'Add Page'}
        btnTxt="Publish"
        submit={submitPost}
        loading={loading}
        back="/page"
      />
      <form onSubmit={submitPost} className="upload-moadal">
        <InputLabel label="Headline" />
        <TextArea
          id="title"
          label="title"
          rows="1"
          onChange={changeTitle}
          value={data.title}
        />
        <InputLabel label={`${DOMAIN}/${data.url}`} />
        <TextArea
          id="url"
          label="url"
          rows="1"
          onChange={(e) => {
            setData({ ...data, url: e.currentTarget.value });
          }}
          value={data.url}
        />
        {/* <BlogMedia>
          {imgUpload ? (
            <img
              className="previous-photo"
              src={`${CDN_URL}/${imgUpload}`}
            ></img>
          ) : (
            <img className="placeholder" src={placeholder}></img>
          )}
          <p>Upload</p>
          <input
            className="update-photo"
            type="file"
            accept="image/*"
            onChange={(event) => onDrop(event)}
          />
        </BlogMedia>
        <TextArea
          id="imageName"
          label="imageName"
          placeholder="Image caption"
          rows="1"
          onChange={(e) => {
            setData({ ...data, imageName: e.currentTarget.value });
          }}
          value={data.imageName}
        /> */}
        {edit && htmlContent && (
          <AdvancedEditor value={htmlContent} ref={inputRef} />
        )}
        {!edit && <AdvancedEditor value={htmlContent} ref={inputRef} />}
        {/* <br />
        <div>
          {sections.map((val, i) => (
            <StandalonSection
              data={val}
              ref={elRefs[i]}
              removeSection={() => removeSection(i)}
            />
          ))}
          <br />
          <Button
            variant="outlined"
            disableElevation
            size="small"
            onClick={addSection}
          >
            + Add Section
          </Button>
        </div> 
        <br />
        <br />*/}
        <br />
      </form>
    </ReadContainer600>
  );
}
