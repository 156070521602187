import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router";
import axios from "axios";
import InputHeading from "../../InputHeading";
import useAuth from "../../../contexts/AuthContext";
import { SERVER_URL } from "../../../config";
import { ReadContainer600 } from "../../share";
import "../UploadProduct.css";
import AdvancedEditor from ".././helper/AdvancedEditor";
import { TextArea } from "../components";
import InputLabel from "../../InputLabel";
const PAGE_NAME = "help";

export default function () {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const inputRef = useRef(null);

  const [contact, setContact] = useState({
    desc: "",
    email: "",
  });
  const [data, setData] = useState({
    url: PAGE_NAME,
    title: "",
    desc: "",
    sections: [],
  });

  const [uploadStatus, setUploadState] = useState({
    isUploading: false,
    uploadPercentage: 0,
    fileUploading: false,
    productUploading: false,
    progressbar: false,
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    axios
      .get(`${SERVER_URL}/api/page/${PAGE_NAME}`)
      .then((res) => {
        setData(res.data);
        setContact(res.data.placeholder?.contact || {});
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  const submitPost = (e) => {
    e.preventDefault();

    if (currentUser && !currentUser.id) {
      return alert("Please log in first.");
    }

    setLoading(true);
    setUploadState({ ...uploadStatus, productUploading: true });

    // Upload instance
    const uploadData = {
      ...data,
      html: inputRef.current.value,
      text: inputRef.current.editor.getText(),
      placeholder: {
        contact,
      },
    };
    axios
      .put(`${SERVER_URL}/api/page/${data._id}`, uploadData)
      .then((res) => {
        navigate("/page");
        setLoading(false);
      })
      .catch(({ response }) => {
        setUploadState({ ...uploadStatus, productUploading: false });
        alert(
          response.err == undefined ? "Internal Server Error" : response.err
        );
        setLoading(false);
      });
  };

  return (
    <ReadContainer600>
      <InputHeading
        title="Edit page"
        btnTxt="Update"
        submit={submitPost}
        loading={loading}
        back="/page"
      />
      <br />
      <h2>Help Page Content</h2>
      <AdvancedEditor ref={inputRef} value={data.html} />
      <InputLabel label="Contact Details" />
      <TextArea
        placeholder="Contact Details"
        rows="3"
        onChange={(e) => {
          setContact({ ...contact, desc: e.currentTarget.value });
        }}
        value={contact.desc}
      />
      <InputLabel label="Email Address" />
      <TextArea
        placeholder="Email"
        rows="1"
        onChange={(e) => {
          setContact({ ...contact, email: e.currentTarget.value });
        }}
        value={contact.email}
      />
    </ReadContainer600>
  );
}
