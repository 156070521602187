import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import IndexView from './views/IndexView';
import LoginView from './views/LoginView.js';
import PageNotFound from './views/PageNotFound.js';
import useAuth from './contexts/AuthContext';
import Settings from './views/Settings';

import Layout from './views/Layout';
import ForgotPassword from './views/ForgotPassword';

import AllPage from './components/read/AllPage';
import AllNotice from './components/read/AllNotice';
import PageInput from './components/edit/PageInput';
import NoticeInput from './components/edit/NoticeInput';

import AllNewsletter from './components/read/AllNewsletter';

import AllAlumni from './components/read/AllAlumni';
import AlumniInput from './components/edit/AlumniInput';

import AllAdmin from './components/read/AllAdmin';
import AdminInput from './components/edit/AdminInput';

import AllBatch from './components/read/AllBatch';
import BatchInput from './components/edit/BatchInput';

import ProfileEditForm from './components/ProfileEditForm';

import HomePageEdit from './components/edit/staticpages/HomePageEdit';
import HelpPageEdit from './components/edit/staticpages/HelpPageEdit';
import SimpleSidePageEdit from './components/edit/staticpages/SimpleSidePageEdit';
import NoticeBoardEdit from './components/edit/staticpages/CommomPageEdit';
import AllContact from './components/read/AllContact';
import FacultyPageInput from './components/edit/FacultyPageInput';
import AllGallery from './components/read/AllGallery';
import GalleryInput from './components/edit/GalleryInput';
import PrintAlumni from './views/PrintAlumni';
import AllMention from './components/read/AllMention';
import MentionInput from './components/edit/MentionInput';

export default function App() {
  const { currentUser } = useAuth();
  return (
    <BrowserRouter>
      <Routes>
        <Route
          exact
          path="/"
          element={currentUser?.id ? <Layout /> : <Navigate to="/login" />}
        >
          <Route path="" element={<IndexView />} />
        </Route>
        <Route
          exact
          path="/dashboard"
          element={currentUser?.id ? <Layout /> : <Navigate to="/login" />}
        >
          <Route path="" element={<IndexView />} />
        </Route>
        <Route
          exact
          path="/newsletter"
          element={currentUser?.id ? <Layout /> : <Navigate to="/login" />}
        >
          <Route path="" element={<AllNewsletter />} />
        </Route>
        <Route
          exact
          path="/batch"
          element={currentUser?.id ? <Layout /> : <Navigate to="/login" />}
        >
          <Route path="" element={<AllBatch />} />
          <Route path="add" element={<BatchInput />} />
          <Route path="edit/:id" element={<BatchInput edit />} />
        </Route>
        <Route
          exact
          path="/page"
          element={currentUser?.id ? <Layout /> : <Navigate to="/login" />}
        >
          <Route path="" element={<AllPage />} />
          <Route path="home" element={<HomePageEdit />} />
          <Route
            path="noticeboard"
            element={
              <NoticeBoardEdit
                page={{
                  title: 'Notice Board',
                  pagename: 'noticeboard',
                }}
              />
            }
          />
          <Route
            path="newsletter"
            element={
              <NoticeBoardEdit
                page={{
                  title: 'Newsletter',
                  pagename: 'newsletter',
                }}
              />
            }
          />
          <Route
            path="help"
            element={
              <HelpPageEdit
                page={{
                  title: 'Help',
                  pagename: 'help',
                }}
              />
            }
          />
          <Route
            path="contacts-links"
            element={
              <SimpleSidePageEdit
                page={{
                  title: 'Useful Links and Contacts',
                  pagename: 'contacts-links',
                }}
              />
            }
          />
          <Route
            path="faq"
            element={
              <SimpleSidePageEdit
                page={{
                  title: 'General FAQ',
                  pagename: 'faq',
                }}
              />
            }
          />
          <Route
            path="registration"
            element={
              <SimpleSidePageEdit
                page={{
                  title: 'Registration',
                  pagename: 'registration',
                }}
              />
            }
          />
          <Route path="add" element={<PageInput />} />
          <Route path="special" element={<FacultyPageInput />} />
          <Route path="special/edit/:id" element={<FacultyPageInput edit />} />
          <Route path="edit/:id" element={<PageInput edit />} />
        </Route>
        <Route
          exact
          path="/notice"
          element={currentUser?.id ? <Layout /> : <Navigate to="/login" />}
        >
          <Route path="" element={<AllNotice />} />
          <Route path="add" element={<NoticeInput />} />
          <Route path="edit/:id" element={<NoticeInput edit />} />
        </Route>
        <Route
          exact
          path="/gallery"
          element={currentUser?.id ? <Layout /> : <Navigate to="/login" />}
        >
          <Route path="" element={<AllGallery />} />
          <Route path="add" element={<GalleryInput />} />
          <Route path="edit/:id" element={<GalleryInput edit />} />
        </Route>
        <Route
          exact
          path="/mention"
          element={currentUser?.id ? <Layout /> : <Navigate to="/login" />}
        >
          <Route path="" element={<AllMention />} />
          <Route path="add" element={<MentionInput />} />
          <Route path="edit/:id" element={<MentionInput edit />} />
        </Route>
        <Route
          exact
          path="/help"
          element={currentUser?.id ? <Layout /> : <Navigate to="/login" />}
        >
          <Route path="" element={<AllContact />} />
        </Route>
        <Route
          exact
          path="/alumni"
          element={currentUser?.id ? <Layout /> : <Navigate to="/login" />}
        >
          <Route path="" element={<AllAlumni />} />
          <Route path="add" element={<AlumniInput />} />
          <Route path="edit/:id" element={<AlumniInput edit />} />
        </Route>
        <Route
          exact
          path="/admin"
          element={currentUser?.id ? <Layout /> : <Navigate to="/login" />}
        >
          <Route path="" element={<AllAdmin />} />
          <Route path="add" element={<AdminInput />} />
          <Route path="edit/:id" element={<AdminInput edit />} />
        </Route>
        <Route exact path="/print" element={<PrintAlumni />} />

        <Route
          path="/login"
          element={currentUser?.id ? <Navigate to="/" /> : <LoginView />}
        />
        <Route
          path="/forgot-password"
          element={currentUser?.id ? <Navigate to="/" /> : <ForgotPassword />}
        />
        <Route
          exact
          path="/settings"
          element={currentUser?.id ? <Layout /> : <Navigate to="/login" />}
        >
          <Route path="" element={<Settings />} />
        </Route>
        <Route
          exact
          path="/profile"
          element={currentUser?.id ? <Layout /> : <Navigate to="/login" />}
        >
          <Route path="" element={<ProfileEditForm />} />
        </Route>

        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </BrowserRouter>
  );
}
