import React, { useRef, useState } from 'react';
import DeleteModal from './DeleteModal';

const withDeleteHandler = (WrappedComponent) => {
  return (props) => {
    const [message, setMessage] = useState(null);
    const ref = useRef(null);

    const alertDelete = (error, cb) => {
      if (cb) ref.current = { cb };
      setMessage(error ? error : 'Are you sure to continue?');
    };

    const clearAlert = (ok) => {
      if (ref.current.cb) {
        ref.current.cb(ok);
      }
      setMessage(null);
    };

    return (
      <>
        <DeleteModal show={message} modalClosed={clearAlert} />
        <WrappedComponent {...props} alertDelete={alertDelete} />
      </>
    );
  };
};

export default withDeleteHandler;
