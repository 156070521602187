import React from "react";
import HelpCenterRoundedIcon from "@mui/icons-material/HelpCenterRounded";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

export default function InputLabel({ label, optional, tooltip }) {
  return (
    <div
      style={{
        fontWeight: "bold",
        fontSize: "12px",
        display: "flex",
        color: "rgb(84 90 105)",
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      <p style={{ color: "black", padding: "5px 0" }}>{label}</p>

      {tooltip && (
        <Tooltip title={tooltip} placement="bottom-start">
          {/* <IconButton> */}
          <HelpCenterRoundedIcon
            style={{ fontSize: "16px", margin: "0 5px" }}
          />
          {/* </IconButton> */}
        </Tooltip>
      )}
      {optional && (
        <p
          style={{
            backgroundColor: "rgba(235,238,241)",
            color: "inherit",
            borderRadius: "4px",
            padding: "2px 5px",
          }}
        >
          Optional
        </p>
      )}
    </div>
  );
}
