import styled from 'styled-components';

export const V6px = styled.div`
  height: 6px;
`;

export const H6px = styled.div`
  width: 6px;
`;

export const H4px = styled.div`
  width: 4px;
`;
