import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import axios from 'axios';
import styled from 'styled-components';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import useAuth from '../../contexts/AuthContext';
import AddHeader from '../AddHeader';
import SubmitLoading from '../SubmitLoading';
import { SERVER_URL } from '../../config';
import { getMediumImage } from '../../utils';
import { ReadContainer600, GridContainerTripple } from '../share';
import withDeleteHandler from '../ui/DeleteWrapper';

const Wrapper = styled.div`
  margin-bottom: 20px;
  .caption {
    margin: 0;
    padding: 0;

    padding: 4px 0;
    font-size: 16px;
    font-weight: 600;
    color: #212529;
    line-height: 20px;
  }
  .max-2-line {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
  }
`;

const ImgWrapper = styled.div`
  position: relative;

  p {
    margin: 0;
    padding: 0;
    font-size: 13px;
    font-weight: 600;
    line-height: 20px;
  }
  .action-wrapper {
    display: flex;
    position: absolute;
    top: 8px;
    right: 8px;
  }
`;

const DeleteWrapper = styled.div`
  border-radius: 50%;
  background-color: #ececec7d;

  :hover {
    svg {
      color: #d32f2f;
    }
  }
`;
const EditWrapper = styled.div`
  margin-right: 4px;

  border-radius: 50%;
  background-color: #ececec7d;

  :hover {
    svg {
      color: black;
    }
  }
`;

const MyComponent = ({ alertDelete }) => {
  const [loading, setLoading] = useState(true);
  const { currentUser } = useAuth();
  const [data, setData] = useState([]);
  const navigate = useNavigate();

  const fetch = () => {
    axios
      .get(`${SERVER_URL}/api/gallery?include=all`)
      .then((res) => {
        setData(res.data);
      })
      .catch(({ response }) => {
        alert(
          response?.data?.message ??
            'Error occurred while fetching data. Please try again later or contact the system administrator'
        );
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetch();
  }, []);

  const handleDelete = (id) => {
    setLoading(true);
    axios
      .delete(`${SERVER_URL}/api/gallery/${id}`, {
        headers: {
          'x-access-token': currentUser.accessToken,
        },
      })
      .then((res) => fetch())
      .catch(({ response }) => {
        alert(
          response?.data?.message ??
            'Error occurred while fetching data. Please try again later or contact the system administrator'
        );
      })
      .finally(() => setLoading(false));
  };

  return (
    <ReadContainer600>
      <AddHeader title="Gallery" />
      <hr />
      <SubmitLoading open={loading} />
      <GridContainerTripple>
        {data?.map((val) => (
          <Wrapper key={val._id} className="wrapper">
            <ImgWrapper>
              <img src={getMediumImage(val.image)} />
              <div className="action-wrapper">
                <EditWrapper>
                  <IconButton onClick={() => navigate(`edit/${val._id}`)}>
                    <EditIcon />
                  </IconButton>
                </EditWrapper>
                <DeleteWrapper>
                  <IconButton
                    id="delete-button"
                    onClick={() => {
                      alertDelete('Are you sure to delete?', (sure) => {
                        if (sure) handleDelete(val._id);
                      });
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </DeleteWrapper>
              </div>
            </ImgWrapper>
            <p className="caption max-2-line">{val.caption}</p>
          </Wrapper>
        ))}
      </GridContainerTripple>
    </ReadContainer600>
  );
};
export default withDeleteHandler(MyComponent);
