import React from 'react';
import { useNavigate } from 'react-router';
import Button from '@mui/material/Button';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';

export default function InputHeading({ title, btnTxt, url, isSuper }) {
  const navigate = useNavigate();
  return (
    <div style={{}}>
      <h2 className="dark-text">{title}</h2>
      {isSuper && (
        <>
          <hr />
          <div>
            <Button
              id="add-button"
              size="small"
              variant="contained"
              onClick={() => navigate(url ? url : 'add')}
              disableElevation
              style={{ textTransformation: 'none', marginRight: '8px' }}
              startIcon={<ManageAccountsIcon />}
            >
              {btnTxt ? btnTxt : 'Add Admin'}
            </Button>
          </div>
        </>
      )}
    </div>
  );
}
