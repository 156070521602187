import { Button } from '@mui/material';
import styled from 'styled-components';
import React from 'react';
import { HoriSpace6px, RowDiv, RowDivAlignCenter } from './share';
import { NewsletterWrapper } from './styles/card';

const Container = styled.div`
  h3 {
    margin: 0;
    padding: 0;

    padding: 4px 0;
    font-size: 16px;
    font-weight: 600;
    color: #212529;
    line-height: 20px;
  }
  p {
    margin: 0;
    margin-top: 6px;
    padding: 0;
    line-height: 1.4;
  }
  .desktop-normal-desc {
  }

  .mobile-normal-desc {
    display: none;
  }

  @media screen and (max-width: 892px) {
    .desktop-normal-desc {
      display: none;
    }

    .mobile-normal-desc {
      display: block;
    }
  }
`;

export default function NormalizeView({
  title,
  image,
  text,
  onDelete,
  onApprove,
  onGoto,
  shorten,
}) {
  let str = text;
  if (str?.length > 240) {
    if (typeof str === 'string' || str instanceof String) {
      str = str.substring(0, 220) + '...';
    }
  }
  let mobile = text;
  if (mobile?.length > 100) {
    if (typeof mobile === 'string' || mobile instanceof String) {
      mobile = mobile.substring(0, 90) + '...';
    }
  }
  let short = text;
  if (short?.length > 35) {
    if (typeof short === 'string' || short instanceof String) {
      short = short.substring(0, 20) + '...';
    }
  }
  return (
    <NewsletterWrapper>
      <RowDivAlignCenter>
        {image && (
          <div style={{ display: 'flex' }}>
            <img style={{ width: '50px', height: '50px' }} src={image} />
            <HoriSpace6px />
          </div>
        )}
        <Container>
          <h3 className="dark-text">{title}</h3>
          {shorten ? (
            <div>
              <p className="dark-text desktop-normal-desc">{str}</p>
              <p className="dark-text mobile-normal-desc">{short}</p>
            </div>
          ) : (
            <div>
              <p className="dark-text desktop-normal-desc">{str}</p>
              <p className="dark-text mobile-normal-desc">{mobile}</p>
            </div>
          )}
        </Container>
      </RowDivAlignCenter>
      <div
        style={{
          height: '12px',
        }}
      />
      <RowDiv>
        {onApprove && (
          <div>
            <Button
              variant="contained"
              color="inherit"
              disableElevation
              size="small"
              onClick={onApprove}
              style={{ backgroundColor: '#00B796', color: 'white' }}
            >
              Approve
            </Button>
          </div>
        )}
        <HoriSpace6px />
        {onGoto && (
          <>
            <Button
              variant="contained"
              size="small"
              onClick={onGoto}
              disableElevation
            >
              Read
            </Button>
          </>
        )}
        <HoriSpace6px />

        <Button
          variant="outlined"
          size="small"
          color="error"
          onClick={onDelete}
        >
          Delete
        </Button>
        <HoriSpace6px />
      </RowDiv>
    </NewsletterWrapper>
  );
}
