import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import axios from 'axios';
import InputHeading from '../../InputHeading';
import useAuth from '../../../contexts/AuthContext';
import { SERVER_URL } from '../../../config';
import { ReadContainer600 } from '../../share';
import '../UploadProduct.css';
import AdvancedEditor from '.././helper/AdvancedEditor';

export default function ({ page }) {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const inputRef = useRef(null);

  const [data, setData] = useState({
    url: page.pagename,
  });
  const [uploadStatus, setUploadState] = useState({
    isUploading: false,
    uploadPercentage: 0,
    fileUploading: false,
    productUploading: false,
    progressbar: false,
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    axios
      .get(`${SERVER_URL}/api/page/${page.pagename}`)
      .then((res) => {
        setData(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  const submitPost = (e) => {
    e.preventDefault();

    if (currentUser && !currentUser.id) {
      return alert('Please log in first.');
    }

    setLoading(true);
    setUploadState({ ...uploadStatus, productUploading: true });

    // Upload instance
    const uploadData = {
      ...data,
      html: inputRef.current.value,
      text: inputRef.current.editor.getText(),
    };

    axios
      .put(`${SERVER_URL}/api/page/${data._id}`, uploadData)
      .then((res) => {
        navigate('/page');
        setLoading(false);
      })
      .catch(({ response }) => {
        setUploadState({ ...uploadStatus, productUploading: false });
        alert(
          response.err == undefined ? 'Internal Server Error' : response.err
        );
        setLoading(false);
      });
  };

  return (
    <ReadContainer600>
      <InputHeading
        title="Edit page"
        btnTxt="Update"
        submit={submitPost}
        loading={loading}
        back="/page"
      />
      <br />
      <h2>{page.title} Page</h2>
      <AdvancedEditor ref={inputRef} value={data.html} />
    </ReadContainer600>
  );
}
