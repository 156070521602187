import styled from 'styled-components';

const HighlightCount = styled.span`
  font-size: 32px;
  font-weight: 700;
  color: #232323;
`;
const HighlightSubTitle = styled.span`
  font-size: 15px;
  color: #232323;
`;
const HighlightCountContainer = styled.div`
  margin: 16px 0px;
  display: flex;
  color: #232323;
  align-items: center;
`;
const HighlightTitle = styled.span`
  font-size: 13px;
  font-weight: 500;
  color: grey;
`;
const HighlightWrapper = styled.div`
  display: flex;
  margin-top: 5px;
  justify-content: space-between;
  width: 100%;
  @media screen and (max-width: 892px) {
    flex-direction: column;
  }
`;
const HighlightContainer = styled.div`
  display: flex;

  @media screen and (max-width: 892px) {
    flex-direction: column;
    margin-right: 0px;
  }
`;

export {
  HighlightWrapper,
  HighlightTitle,
  HighlightSubTitle,
  HighlightContainer,
  HighlightCountContainer,
  HighlightCount,
};
