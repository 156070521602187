import React, { useState } from 'react';
import axios from 'axios';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { SERVER_URL } from '../config';
import { CenterdDiv, SpaceBetween } from './share';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

export default function ProfileEditForm({ accessToken, data, onClose }) {
  const [showOldPassword, setShowOldPassword] = React.useState(false);
  const [showNewPassword, setShowNewPassword] = React.useState(false);

  const [submitting, setSubmitting] = useState(false);
  const [input, setInput] = useState({
    oldPassword: '',
    newPassword: '',
    email: data.email,
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    setSubmitting(true);
    await axios
      .post(`${SERVER_URL}/api/admin/change-password`, input, {
        headers: { 'x-access-token': accessToken },
      })
      .then((res) => {
        alert('Password has been changed successfully!');
        onClose();
        window.location.reload();
      })
      .catch((err) => {
        if (err.response.data?.message) {
          alert(JSON.stringify(err.response.data.message));
        } else {
          alert('Error occured while saving data! Please try again later.');
        }
      });
    setSubmitting(false);
  };

  return data ? (
    <div
      style={{
        width: '100%',
        maxWidth: '480px',
      }}
    >
      <SpaceBetween>
        <h3>Change password</h3>
        <IconButton onClick={onClose}>
          <CloseRoundedIcon />
        </IconButton>
      </SpaceBetween>
      <br />
      <form
        style={{ display: 'flex', flexDirection: 'column' }}
        onSubmit={handleSubmit}
      >
        <FormControl variant="outlined">
          <InputLabel htmlFor="outlined-adornment-password">
            Old Password
          </InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            type={showOldPassword ? 'text' : 'password'}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowOldPassword((show) => !show)}
                  onMouseDown={(event) => {
                    event.preventDefault();
                  }}
                  edge="end"
                >
                  {showOldPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="Old Password"
            value={input.oldPassword}
            defaultValue={input.oldPassword}
            onChange={(e) => {
              setInput({ ...input, oldPassword: e.target.value });
            }}
          />
        </FormControl>

        <br />
        <FormControl variant="outlined">
          <InputLabel htmlFor="outlined-adornment-password">
            New Password
          </InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            type={showNewPassword ? 'text' : 'password'}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowNewPassword((show) => !show)}
                  onMouseDown={(event) => {
                    event.preventDefault();
                  }}
                  edge="end"
                >
                  {showNewPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="New Password"
            value={input.newPassword}
            defaultValue={input.newPassword}
            onChange={(e) => {
              setInput({ ...input, newPassword: e.target.value });
            }}
          />
        </FormControl>
        <br />

        <CenterdDiv>
          <Button
            disableElevation
            fullWidth
            disabled={submitting}
            variant="contained"
            size="medium"
            type="submit"
            sx={{ maxWidth: '110px', height: '40px' }}
          >
            Change
          </Button>
        </CenterdDiv>
      </form>
    </div>
  ) : (
    <span>loading...</span>
  );
}
